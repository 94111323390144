<template>
  <div>
    <b-tabs>
      <b-tab :title="lang('t_advanced')">
        <b-card>
          <!-- Projeler-->
          <v-select
            class="mb-1"
            :placeholder="lang('t_project')"
            v-model="selected_project"
            label="display_name"
            :reduce="(val) => val.internal_name"
            :options="projects"
          >
          </v-select>
          <v-select
            class="mb-1"
            :placeholder="lang('t_queue')"
            v-model="selected_queue"
            label="display_name"
            :reduce="(val) => val.internal_name"
            :options="queues.filter((e) => e.project == selected_project)"
          >
          </v-select>

          <!-- Dosya Yükleme-->
          <b-form-file
            @input="read_excel"
            v-model="files"
            :placeholder="lang('t_uploadFileOnClick')"
            :drop-placeholder="lang('t_uploadFile')"
            accept=".xlsx"
            no-drop
            :browse-text="lang('t_uploadFile')"
          />

          <!-- CheckBox -->
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_passive_unmatch_records">
              {{ lang("t_setUnmatchedRecordsToPassive") }}
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="update_match_records">
              {{ lang("t_setUnmatchedRecordsToUpdate") }}
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_passive">
              {{ lang("t_setAllPreviousInstitutionDataToPassive") }}
            </b-form-checkbox>
          </div>

          <!-- <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_attempts">
              {{ lang("t_setAttemptsByPhone") }}
            </b-form-checkbox>
          </div> -->

          <!-- Kaydet Butonu -->

          <b-button @click="upload" variant="primary" style="float: right">
            <span v-if="!is_progress">{{ lang("t_upload") }}</span>
            <b-spinner v-else />
          </b-button>
          <b-button
            class="mr-1"
            href="template_examples/voyce_data_pattern.xlsx"
            :exact="true"
            variant="success"
            style="float: right"
          >
            {{ lang("t_exampleFile") }}
          </b-button>
        </b-card>
      </b-tab>
      <b-tab :title="lang('t_basic')">
        <b-card>
          <!-- Projeler-->
          <v-select
            class="mb-1"
            :placeholder="lang('t_project')"
            v-model="selected_project"
            label="display_name"
            :reduce="(val) => val.internal_name"
            :options="projects"
          >
          </v-select>
          <v-select
            class="mb-1"
            :placeholder="lang('t_queue')"
            v-model="selected_queue"
            label="display_name"
            :reduce="(val) => val.internal_name"
            :options="queues.filter((e) => e.project == selected_project)"
          >
          </v-select>

          <!-- Dosya Yükleme-->
          <b-form-file
            @input="read_excel"
            v-model="files"
            :placeholder="lang('t_uploadFileOnClick')"
            :drop-placeholder="lang('t_uploadFile')"
            accept=".xlsx"
            no-drop
            :browse-text="lang('t_uploadFile')"
          />

          <!-- CheckBox -->
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_passive_unmatch_records">
              {{ lang("t_setUnmatchedRecordsToPassive") }}
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="update_match_records">
              {{ lang("t_setUnmatchedRecordsToUpdate") }}
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_passive">
              {{ lang("t_setAllPreviousInstitutionDataToPassive") }}
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="set_attempts">
              {{ lang("t_setAttemptsByPhone") }}
            </b-form-checkbox>
          </div>

          <b-row>
            <b-col cols="12">
              <h6 class="mt-1 text-muted">
                *Sadece Telefon başlığı zorunludur.
              </h6>
              <h6 class="text-muted">
                *Müşteri No boş bırakılan satırlar otomatik olarak
                doldurulacaktır.
              </h6>
            </b-col>
          </b-row>

          <!-- Kaydet Butonu -->

          <b-button @click="uploadBasic" variant="primary" style="float: right">
            <span v-if="!is_progress">{{ lang("t_upload") }}</span>
            <b-spinner v-else />
          </b-button>
          <b-button
            class="mr-1"
            href="template_examples/basic_customer_upload.xlsx"
            :exact="true"
            variant="success"
            style="float: right"
          >
            {{ lang("t_exampleFile") }}
          </b-button>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BFormFile,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BSpinner,
  BTab,
  BTabs
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";

globalThis.xw = function xw(data, cb) {
  var worker = new Worker("./js/xlsx-worker.js");
  worker.onmessage = function (e) {
    switch (e.data.t) {
      case "ready":
        break;
      case "e":
        console.error(e.data.d);
        break;
      case "xlsx":
        cb(JSON.parse(e.data.d));
        break;
    }
  };
  worker.postMessage({ d: data, b: "binary" });
};

export default {
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BCardCode,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BSpinner,
    BTabs,
    BTab
  },

  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    filter_values: {
      selectedProject: [],
    },
    is_progress: false,
    files: {},
    queues: [],
    projects: [],
    selected_project: "",
    cols: {
      customers: [],
      products: [],
      phones: [],
    },
    data: {
      customers: [],
      products: [],
      phones: [],
    },
    selectedColumn: "",
    selected_cols: [],
    set_passive: false,
    set_attempts: false,
    set_passive_unmatch_records: false,
    update_match_records: false,
  }),
  methods: {
    onexport(tmp_data) {
      let _data = [];
      for (const row of tmp_data) {
        let obj = {};

        for (const column of Object.keys(row)) {
          // let key = _headers[column] == undefined ? column : _headers[column];
          obj[column] = row[column];
        }
        _data.push(obj);
      }

      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(wb, data, `duplicate_data`); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(wb, `duplicate_data_${new Date().getTime()}.xlsx`); // name of the file is 'book.xlsx'
    },

    get_projects: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Project`)).data;
      this.projects = response;
    },
    get_queues: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Queue`)).data;
      this.queues = response;

      //
    },
    read_xlsx_into_variables(file) {
      return new Promise((resolve, reject) => {
        this.is_progress = true;
        const reader = new FileReader();
        reader.onload = (e) => {
          const bstr = e.target.result;
          globalThis.xw(bstr, (jsondata) => {
            this.data.customers = jsondata.customers;
            this.cols.customers = jsondata.customers[0];

            // this.data.products = jsondata.products;
            // this.cols.products = jsondata.products[0];

            this.data.phones = jsondata.phones;
            this.cols.phones = jsondata.phones[0];

            this.is_progress = false;
            resolve();
          });
        };
        reader.readAsBinaryString(file);
      });
    },
    async read_excel() {
      await this.read_xlsx_into_variables(this.files);
      console.log("data", this.data);
      console.log("cols", this.cols);
    },
    async upload() {
      /* if (this.update_match_records && this.selected_cols.length == 0) {
        this.snackbarMessage = "Lütfen güncellenecek olan başlıkları seçiniz.";
        this.snackbarColor = 'warning';
        this.snackbar = true;
        return;
      } */
      this.is_progress = true;

      try {
        let formData = new FormData();
        formData.append("files", this.files, this.files.name);
        formData.append("selected_queue", this.selected_queue);
        formData.append("selectedColumn", this.selectedColumn);
        formData.append("selected_cols", this.selected_cols);
        formData.append("collection", `${this.selected_project}_customers`);
        formData.append("set_passive", this.set_passive);
        formData.append("set_attempts", this.set_passive);

        formData.append(
          "set_passive_unmatch_records",
          this.set_passive_unmatch_records
        );
        formData.append("update_match_records", this.update_match_records);

        let response = await this.$http_in.post(
          "crm/v1/UploadCRMCustomers",
          formData
        );

        // await this.SetCustomers();
        console.log("dataa", response);
        // if (response.data.data == undefined) {
        //   this.snackbarMessage = response.data;
        //   this.snackbarColor = 'warning';
        //   this.snackbar = true;
        //   this.is_progress = false;
        //   return;
        // }

        if (response.data.duplicate_records.length > 0) {
          // globalThis.window.location.href = `http://${globalThis.AppConfig.RESTAPI}/outputs/${response.data.url}.xlsx`;
          this.onexport(response.data.duplicate_records);
        }

        if (response && response.data.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        console.log("ERROR", error);
      }

      this.$swal({
        title: globalThis._lang("t_processSuccess"),
        text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.is_progress = false;
    },
    async uploadBasic() {
      /* if (this.update_match_records && this.selected_cols.length == 0) {
        this.snackbarMessage = "Lütfen güncellenecek olan başlıkları seçiniz.";
        this.snackbarColor = 'warning';
        this.snackbar = true;
        return;
      } */
      this.is_progress = true;

      try {
        let formData = new FormData();
        formData.append("files", this.files, this.files.name);
        formData.append("selected_queue", this.selected_queue);
        formData.append("selectedColumn", this.selectedColumn);
        formData.append("selected_cols", this.selected_cols);
        formData.append("collection", `${this.selected_project}_customers`);
        formData.append("set_passive", this.set_passive);
        formData.append("set_attempts", this.set_attempts);

        formData.append(
          "set_passive_unmatch_records",
          this.set_passive_unmatch_records
        );
        formData.append("update_match_records", this.update_match_records);

        let response = await this.$http_in.post(
          "crm/v1/UploadCRMCustomersBasic",
          formData
        );

        // await this.SetCustomers();
        console.log("dataa", response);
        // if (response.data.data == undefined) {
        //   this.snackbarMessage = response.data;
        //   this.snackbarColor = 'warning';
        //   this.snackbar = true;
        //   this.is_progress = false;
        //   return;
        // }

        if (response.data.duplicate_records.length > 0) {
          // globalThis.window.location.href = `http://${globalThis.AppConfig.RESTAPI}/outputs/${response.data.url}.xlsx`;
          this.onexport(response.data.duplicate_records);
        }

        if (response && response.data.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        console.log("ERROR", error);
      }

      this.$swal({
        title: globalThis._lang("t_processSuccess"),
        text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.is_progress = false;
    },
  },
  mounted: function () {
    this.get_projects();
    this.get_queues();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

