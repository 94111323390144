<template>
  <b-row>
    <b-col class="pt-0">
      <!--   v-if="columns.length > 0" -->
      <v-select
        multiple
        v-model="assign_agent_info.column"
        :options="['Müşteri No', 'TCKN', 'Ek Alan 1']"
        :placeholder="lang('t_matchingColumn')"
      ></v-select>

      <!-- form-file v-if="
          assign_agent_info.data.length == 0 && assign_agent_info.column != ''
        " -->
      <b-form-file
        v-model="files"
        class="mt-1"
        @input="read_excel"
        :placeholder="lang('t_uploadFileOnClick')"
        drop-placeholder="Dosya yükle..."
        id="extension"
        accept=".xlsx, .xls"
        no-drop
      />
      <!-- <v-text-field v-if="files == '' && assign_agent_info.column != ''" v-model="assign_agent_info.data" dense
        clearable class="fontFeatures" :label="assign_agent_info.column"
        :placeholder="`${assign_agent_info.column} 1, ${assign_agent_info.column} 2, ${assign_agent_info.column} 3 ...`"
        outlined>
      </v-text-field>

      <v-autocomplete v-model="assign_agent_info.agent" v-if="assign_agent_info.column != '' && files == ''"
        :items="agents" class="fontFeatures" label="Temsilci" outlined dense item-text="short_username"
        item-value="username">
      </v-autocomplete> -->

      <b-button
        @click="data_management"
        style="float: right"
        class="btn-success mt-1"
      >
        <span v-if="!is_progress">{{ lang("t_save") }}</span>
        <b-spinner v-else />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BLink,
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BSpinner,
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormFile,
  },
  props: {
    project: "",
  },
  methods: {
    async read_excel() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = this.$xlsx.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = this.$xlsx.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);
        this.excel.data = _data;
        this.excel.keys = Object.keys(_data[0]);
        console.log(this.excel.data);
      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    data_management: async function () {
      this.is_progress = true;
      console.log("data_management");
      // if (this.assign_agent_info.column == '' || this.excel.data == []) {
      //   this.snackbarMessage = globalThis._lang("t_pleaseFillTheAllNecessaryAreas");
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }

      // if (!this.excel.keys.includes(this.assign_agent_info.column)) {
      //   this.snackbarMessage = globalThis._lang("t_matchingColumnMustBeTheSameInTheExcelColumn");
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }
      // if (!this.excel.keys.includes('assigned_agent')) {
      //   this.snackbarMessage = `Excel datanızda assigned_agent sütunu bulunamadı.`
      //   this.snackbarColor = "warning";
      //   this.snackbar = true;
      //   return;
      // }

      var response = (
        await this.$http_in.put(`crm/v1/DataManagement`, {
          collection: `${this.project}_customers`,
          assign_agent_info: this.excel.data,
          column: this.assign_agent_info.column,
        })
      ).data;

      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      // console.log("assign_agent", response);
    },
  },
  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    columns: [],
    agents: [],
    excel: {
      data: [],
      keys: [],
    },
    is_progress: false,
    files: null,
    assign_agent_info: {
      column: "",
      data: "",
      agent: "",
    },
  }),
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
