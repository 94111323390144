<template>
  <div>
    <b-card>
      <!-- Projeler-->
      <v-select
        @input="on_project_changed"
        class="mb-1"
        :placeholder="lang('t_project')"
        v-model="currentData.project"
        label="display_name"
        :reduce="(val) => val.internal_name"
        :options="projects"
      >
      </v-select>
      <div>
        <draggable
          :list="columns"
          :group="{ name: 'g1', pull: 'clone', put: false }"
          :clone="cloneItem"
        >
          <b-badge
            style="width: 5.2vw"
            class="mr-50 mb-50 badge-glow cursor-move"
            v-for="(item, i) in columns"
            :key="i"
            variant="primary"
          >
            {{ item.display_name }}
          </b-badge>
        </draggable>
      </div>

      <draggable
        class="dragArea list-group card"
        tag="ul"
        :list="list2"
        :group="{ name: 'g1', type: 'script' }"
        @add="changeItemId"
      >
        <quill-editor v-model="currentData.content" :options="snowOption" />
      </draggable>

      <!-- Kaydet Butonu -->
      <b-button
        class="mt-1"
        @click="update_script"
        variant="success"
        style="float: right"
      >
        {{ lang("t_save") }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BCard, BCardText, BLink, BFormFile, BFormSelect, BFormCheckbox, BButton } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import draggable from 'vuedraggable'


export default {
  components: {
    draggable,
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BCardCode,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BBadge
  },

  data: () => ({
    lang: _l(window.localStorage.getItem('language') || "tr"),
    is_progress: false,
    projects: [],
    script: [],
    currentData: {
      project: '',
      content: '',
    },
    snowOption: {
      placeholder: globalThis._lang('t_createScript'),
      theme: 'snow'

    },
    column_labels: {
      status: "Durum",
      assigned_agent: "Agent",
      attempts: "Temas",
      campaign: "Kampanya",
      finish_code: "Son Sonuç Kodu",
      insert_date: "Arama T.",
      action_date: "Aranacak T.",
      create_date: "Yüklenme T.",
      ex_agent: "Ex Agent",
      assign_date: "Atama T.",
    },
    columns: [],
    list2: [],

  }
  ),
  methods: {
    get_projects: async function () {
      var response = (
        await this.$http_in.get(`/optmgr/v1/Project`)
      ).data;
      this.projects = response;
    },
    update_script: async function () {
      var response = (
        await this.$http_in.post(`/crm/v1/Script`, { data: this.currentData })
      ).data;

      if (response.acknowledged) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          // text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }
    },
    get_script: async function () {
      var response = (
        await this.$http_in.get(`/crm/v1/Script`)
      ).data;

      this.script = response[0];
    },
    on_project_changed() {
      this.currentData.content = this.script.content;
      this.get_columns();
    },
    get_columns: async function () {
      this.columns = [];
      var response = (
        await this.$http_in.get(`spv/v1/ProjectColumns/${this.currentData.project}_customers`)
      ).data;
      for (const item of response[0].allkeys) {
        if (['active', '_id', 'cycles', 'TCKN_masked', 'notes', 'phones', 'products', 'campaign', 'sms', 'extra_data', 'daily_attempts', 'update_date', 'assigned_agent2'].includes(item)) {
          continue;
        }
        this.columns.push({
          internal_name: item,
          display_name: this.column_labels[item] == undefined ? item : this.column_labels[item]
        })
      }
    },
    cloneItem(item) {
      this.currentData.content += ` {{${item.internal_name}}} `
    },

  },
  mounted: function () {
    this.get_projects();
    this.get_script();
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss"
</style>

