<template>
  <!-- style="display: contents" -->
  <b-container
    fluid
    :class="['px-0', 'col', !is_selected ? 'override-overflow' : '']"
  >
    <div
      v-if="is_progress"
      style="
        z-index: 999;
        position: absolute;
        height: 100vh;
        width: 63vw;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        overflow-y: hidden;
      "
    >
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem"
        label="Loading..."
      />
    </div>
    <b-card style="min-height: 85vh" v-if="!is_selected" class="mb-0 pb-0">
      <b-row class="mb-1">
        <b-col cols="3">
          <b-button-group>
            <b-dropdown
              size="sm"
              v-model="selected_call_list"
              :text="selected_call_list.display_name"
              variant="primary"
            >
              <b-dropdown-item
                v-for="(item, i) in call_list_items"
                :key="i"
                @click="selected_call_list = item"
              >
                {{ item.display_name }}
              </b-dropdown-item>
            </b-dropdown>
            <!-- <v-select
              style="width: 200px"
              id="contactList"
              :options="[lang('t_contactList'), lang('t_contactPool')]"
              :placeholder="lang('t_contactList')"
              :reduce="(val) => val.label"
            ></v-select> -->

            <b-button
              @click="getCustomers"
              size="sm"
              v-b-tooltip.hover.top="lang('t_get')"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
            >
              {{ lang("t_get") }}
            </b-button>
          </b-button-group>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-input-group>
              <!-- @keydown.native="getCustomerBySearch" -->

              <b-form-input
                size="sm"
                @keydown.enter="getCustomerBySearch"
                v-model="get_customer_search_term"
                id="search"
                :placeholder="lang('t_searchPlaceHolder')"
                trim
                type="search"
              />
              <b-input-group-append>
                <b-dropdown
                  size="sm"
                  v-model="get_customer_record_per_page"
                  :text="get_customer_record_per_page"
                  variant="primary"
                >
                  <b-dropdown-item
                    type="number"
                    v-for="(item, i) in ['15', '50', '100', '500', '1000']"
                    :key="i"
                    @click="recordPerPage(item)"
                  >
                    {{ item }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-append>
            </b-input-group>
          </b-row>
        </b-col>
        <b-col cols="3">
          <!-- :v-b-tooltip.hover.top="lang('t_get')" -->
          <b-button-group class="float-right">
            <b-button
              v-if="crm_permissions.includes('fields')"
              class="float-right border-0"
              size="sm"
              v-b-tooltip.hover.top="lang('t_titles')"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              @click="modal_column_settings = true"
            >
              <feather-icon icon="ColumnsIcon"></feather-icon>
            </b-button>
            <b-button
              v-if="crm_permissions.includes('filter')"
              class="float-right border-0"
              size="sm"
              @click="modal_filters = true"
              v-b-tooltip.hover.top="lang('t_filters')"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
            >
              <feather-icon icon="FilterIcon"></feather-icon>
            </b-button>
            <b-button
              v-if="crm_permissions.includes('add_customer')"
              class="float-right border-0"
              size="sm"
              @click="open_add_customer"
              v-b-tooltip.hover.top="lang('t_addCustomer')"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
            >
              <feather-icon icon="UserPlusIcon"></feather-icon>
            </b-button>
            <b-dropdown
              v-if="
                crm_permissions.includes('management') ||
                crm_permissions.includes('excel_customer') ||
                crm_permissions.includes('excel_customer_by_phone')
              "
              class="float-right"
              size="sm"
              v-b-tooltip.hover.top="lang('t_management')"
              v-b-tooltip.hover.v-primary
              right
              variant="outline-primary"
              :text="lang('t_management')"
            >
              <b-dropdown-item
                v-if="crm_permissions.includes('management')"
                @click="modal_assign_agent = true"
                >{{ lang("t_agentAssign") }}</b-dropdown-item
              >
              <b-dropdown-divider
                v-if="crm_permissions.includes('management')"
              />

              <b-dropdown-item
                v-if="crm_permissions.includes('management')"
                @click="on_management_menu_item_clicked('data_upload')"
                >{{ lang("t_dataUpload") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="crm_permissions.includes('management')"
                @click="on_management_menu_item_clicked('data_management')"
                >{{ lang("t_dataManagement") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="crm_permissions.includes('management')"
                @click="on_management_menu_item_clicked('script_management')"
                >{{ lang("t_scriptManagement") }}</b-dropdown-item
              >
              <b-dropdown-divider
                v-if="crm_permissions.includes('management')"
              />

              <b-dropdown-item
                v-if="
                  customer_list.length > 0 &&
                  crm_permissions.includes('excel_customer')
                "
                @click="CustomerListExport"
                >{{ lang("t_customerList") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  customer_list.length > 0 &&
                  crm_permissions.includes('excel_customer_by_phone')
                "
                @click="CustomerListWithPhoneDetailsExport"
                >{{ lang("t_customerListByPhone") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  customer_list.length > 0 &&
                  crm_permissions.includes('excel_customer_by_product')
                "
                @click="CustomerListWithProductDetailsExport"
                >{{ lang("t_customerListByProduct") }}</b-dropdown-item
              >
              <!-- <b-dropdown-item @click="export_csv">{{
              lang("t_customerListByCSV")
            }}</b-dropdown-item> -->
            </b-dropdown>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row style="display: grid">
        <div style="overflow: auto; max-height: 73vh">
          <b-table
            :tbody-tr-class="rowClass"
            :tbody-td-class="rowClass"
            hover
            outlined
            responsive="lg"
            :items="customer_list"
            :fields="headers.filter((e) => e.visible == true)"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @row-dblclicked="selectCustomer"
            style="max-height: 97%"
          >
            <template #cell()="data">
              <span class="cell-style">{{ data.value }}</span>
            </template>
            <!-- <template #cell(status)="data">
              <span
                v-b-tooltip.hover.top="data.value"
                v-b-tooltip.hover.v-primary
              >
                {{ data.value }}
              </span>
            </template> -->
            <template #cell(Kuyruk)="data">
              <span class="cell-style">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues
                        .find((e) => e.internal_name == data.value)
                        .display_name.replace(/ /g, "\u00a0")
                    : "Bulunamadı"
                }}
              </span>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="mt-25">
        <b-col cols="12">
          <span>
            <feather-icon
              @click="clearFilter"
              v-b-tooltip.hover.top="lang('t_clearFilters')"
              v-b-tooltip.hover.v-danger
              class="cursor-pointer text-danger"
              icon="Trash2Icon"
            ></feather-icon>
            {{ filter_beautify }}
          </span>

          <b-pagination
            v-model="get_customer_page"
            :total-rows="get_customer_total_record"
            hide-goto-end-buttons
            :per-page="get_customer_record_per_page"
            size="sm"
            class="my-0 float-right"
            @input="next"
          />
          <code class="mt-25 mx-50 pr-1 float-right">
            {{ get_customer_total_record }}
          </code>
          <b-button
            v-b-modal.modal-color-info
            variant="flat-primary"
            size="sm"
            class="pr-1 float-right"
          >
            <feather-icon icon="InfoIcon"></feather-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- CUSTOMER DETAILS -->
    <b-container fluid class="px-0" v-else>
      <b-row class="mb-1">
        <b-col>
          <b-button
            style="width: 10em"
            size="sm"
            @click="is_selected = false"
            variant="outline-primary"
          >
            {{ lang("t_backToList") }}
          </b-button>
        </b-col>
        <b-col>
          <b-button
            style="width: 10em"
            class="float-right mr-1 ml-1"
            size="sm"
            @click="next_customer"
            variant="outline-primary"
          >
            {{ lang("t_nextCustomer") }}
          </b-button>
          <b-button-group size="sm" class="float-right">
            <b-button
              @click="modal_old_notes = true"
              v-if="old_notes.length > 0"
              size="sm"
              variant="outline-primary"
            >
              {{ lang("t_notes") }}</b-button
            >
            <b-button
              v-if="selected_customer['Araç'] == 'Var'"
              @click="open_modal_special_data('Araç')"
              size="sm"
              variant="outline-primary"
            >
              {{ lang("t_vehicle") }}</b-button
            >
            <b-button
              v-if="selected_customer['Tapu'] == 'Var'"
              @click="open_modal_special_data('Tapu')"
              size="sm"
              variant="outline-primary"
            >
              {{ lang("t_landRegistry") }}</b-button
            >
            <b-button
              v-if="selected_customer['SSK'] == 'Var'"
              @click="open_modal_special_data('SSK')"
              size="sm"
              variant="outline-primary"
              >{{ lang("t_SSK") }}</b-button
            >
            <b-button
              v-if="selected_customer['Mernis'] == 'Var'"
              @click="open_modal_special_data('Mernis')"
              size="sm"
              variant="outline-primary"
            >
              {{ lang("t_mernis") }}</b-button
            >
            <b-button
              v-if="selected_customer['Gsm Sorgu'] == 'Var'"
              @click="open_modal_special_data('Gsm Sorgu')"
              size="sm"
              variant="outline-primary"
              >{{ lang("t_gsmQuery") }}</b-button
            >
            <b-button
              v-if="selected_customer['Muzekkere'] == 'Var'"
              @click="open_modal_special_data('Muzekkere')"
              size="sm"
              variant="outline-primary"
            >
              {{ lang("t_negotiation") }}</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-card no-body class="business-card">
            <b-card-header class="pb-1">
              <b-card-title class="text-primary">{{
                lang("t_customerInformation")
              }}</b-card-title>
              <b-row class="px-1">
                <feather-icon
                  v-if="crm_permissions.includes('edit_customer')"
                  @click="openUpdateCustomerModal"
                  icon="EditIcon"
                  size="18"
                  class="cursor-pointer text-primary mx-25"
                />
                <feather-icon
                  v-if="crm_permissions.includes('extra_data')"
                  @click="modal_extra_data = true"
                  icon="PlusIcon"
                  size="18"
                  class="cursor-pointer text-primary mx-25"
                />
              </b-row>
            </b-card-header>

            <b-card-body>
              <div class="business-items">
                <div class="business-item">
                  <div class="d-flex align-items-center justify-content-start">
                    <span
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="'Adı Soyadı'"
                      style="
                        display: inline-block;
                        width: 4vw;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                      "
                      class="text-dark font-font-weight-font-weight-bolder"
                      >Adı Soyadı:</span
                    >

                    <span
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="selected_customer['Adı Soyadı']"
                      style="
                        display: inline-block;
                        width: 7vw;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                      "
                      class="text-dark ml-50"
                    >
                      {{ selected_customer["Adı Soyadı"] }}
                    </span>
                  </div>
                </div>
                <div class="business-item">
                  <div class="d-flex align-items-center justify-content-start">
                    <span
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="'Müşteri No'"
                      style="
                        display: inline-block;
                        width: 4vw;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                      "
                      class="text-dark font-font-weight-font-weight-bolder"
                      >Müşteri No:</span
                    >

                    <span
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="selected_customer['Müşteri No']"
                      style="
                        display: inline-block;
                        width: 7vw;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                      "
                      class="text-dark ml-50"
                    >
                      {{ selected_customer["Müşteri No"] }}
                    </span>
                  </div>
                </div>
                <div
                  v-for="(item, i) in headers.filter(
                    (e) =>
                      typeof selected_customer[e.key] != 'object' &&
                      selected_customer[e.key] != '' &&
                      e.visible &&
                      ![
                        '_id',
                        'Araç',
                        'Tapu',
                        'Mernis',
                        'Muzekkere',
                        'SSK',
                        'Gsm Sorgu',
                        'Müşteri No',
                        'Adı Soyadı',
                      ].includes(e.key)
                  )"
                  :key="i"
                  class="business-item"
                >
                  <!-- :style="{
                    backgroundColor: i % 3 == 0 ? null : t_colors[i % 5],
                  }" -->
                  <div class="d-flex align-items-center justify-content-start">
                    <span
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="
                        column_labels[item.key] == undefined
                          ? item.label
                          : column_labels[item.key]
                      "
                      style="
                        display: inline-block;
                        width: 4vw;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                      "
                      class="text-dark font-font-weight-font-weight-bolder"
                      >{{
                        column_labels[item.key] == undefined
                          ? item.label
                          : column_labels[item.key]
                      }}:</span
                    >
                    <span
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="
                        queues.find(
                          (e) => e.internal_name == selected_customer[item.key]
                        )
                          ? queues.find(
                              (e) =>
                                e.internal_name == selected_customer[item.key]
                            ).display_name
                          : selected_customer[item.key]
                      "
                      style="
                        display: inline-block;
                        width: 7vw;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                      "
                      v-if="item.key == 'Kuyruk'"
                      class="text-dark ml-50"
                    >
                      {{
                        queues.find(
                          (e) => e.internal_name == selected_customer[item.key]
                        )
                          ? queues.find(
                              (e) =>
                                e.internal_name == selected_customer[item.key]
                            ).display_name
                          : selected_customer[item.key]
                      }}
                    </span>
                    <span
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="selected_customer[item.key]"
                      v-else
                      style="
                        display: inline-block;
                        width: 7vw;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                      "
                      class="text-dark ml-50"
                    >
                      {{ selected_customer[item.key] }}
                    </span>
                  </div>
                </div>
              </div>
            </b-card-body>
            <b-card-header class="pt-1 pb-1">
              <b-card-title class="text-primary">{{
                lang("t_extraInformation")
              }}</b-card-title>
            </b-card-header>
            <div class="business-items mx-2 mb-2">
              <div
                v-for="(item, i) in customer_extra_info"
                :key="i"
                class="business-item"
              >
                <div class="d-flex align-items-center justify-content-start">
                  <span
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="item.column"
                    style="
                      display: inline-block;
                      width: 4vw;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                    class="text-dark font-font-weight-font-weight-bolder"
                    >{{ item.column }}:</span
                  >
                  <span
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="item.value"
                    style="
                      display: inline-block;
                      width: 7vw;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    "
                    class="text-dark ml-50"
                  >
                    {{ item.value }}
                  </span>
                  <feather-icon
                    @click="delete_extra_data(item)"
                    icon="Trash2Icon"
                    size="18"
                    class="cursor-pointer ml-auto text-danger"
                    v-b-tooltip.hover.top="'Sil'"
                    v-b-tooltip.hover.v-danger
                  />
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col cols="9">
          <b-tabs content-class="pt-1" fill>
            <!-- SCRİPT -->
            <b-tab
              v-if="script.length > 0 && crm_permissions.includes('script')"
              :title="lang('t_script')"
            >
              <b-card
                v-if="script.length > 0 && crm_permissions.includes('script')"
                class="mb-0"
              >
                <td v-html="script"></td>
              </b-card>
            </b-tab>
            <!-- PHONES -->
            <b-tab
              v-if="crm_permissions.includes('phones')"
              :title="lang('t_phones')"
            >
              <b-card v-if="crm_permissions.includes('phones')">
                <b-card-title>
                  <b-button
                    style="width: 8em"
                    @click="modal_add_phone = true"
                    size="sm"
                    variant="gradient-primary"
                    >{{ lang("t_addPhone") }}</b-button
                  >
                </b-card-title>
                <b-row class="mx-0" style="display: grid">
                  <b-table
                    style="width: 100%; overflow: auto"
                    bordered
                    small
                    :items="phone_list"
                    :fields="phone_list_header"
                    striped
                    :responsive="true"
                    ref="tablePhone"
                    :tbody-tr-class="rowClassPhone"
                  >
                    <template #cell(show_details)="row">
                      <b-button
                        @click="row.toggleDetails"
                        size="sm"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon
                          :icon="
                            !row.detailsShowing
                              ? 'ArrowDownIcon'
                              : 'ArrowUpIcon'
                          "
                        ></feather-icon>
                      </b-button>
                    </template>

                    <template #cell(Telefon)="data">
                      <a
                        class="my-auto"
                        href="#"
                        @click="start_preview_call(data.item.Telefon)"
                      >
                        {{
                          other_permissions.includes(
            "mask_remote_number"
          )
            ? maskPhoneNumber(data.item.Telefon)
            : data.item.Telefon,
                        }}</a
                      >

                      <b-button
                        v-if="crm_permissions.includes('sms_send')"
                        @click="
                          modal_send_sms = true;
                          sms_phone = data.item.Telefon;
                        "
                        variant="outline-primary"
                        class="ml-25 px-50 py-25 float-right"
                        size="sm"
                      >
                        <feather-icon icon="SendIcon"></feather-icon>
                      </b-button>
                      <b-button
                        @click="
                          () => {
                            f_action.contact_number = data.item.Telefon;
                            modal_finish_code = true;
                          }
                        "
                        class="ml-25 px-50 py-25 float-right"
                        size="sm"
                        variant="outline-primary"
                      >
                        <feather-icon icon="TagIcon"></feather-icon>
                      </b-button>

                      <!-- <b-button
                      @click="start_preview_call(data.item.Telefon)"
                      v-if="crm_permissions.includes('call')"
                      variant="outline-primary"
                      class="ml-25 px-50 py-50"
                      size="sm"
                      >{{ lang("t_call") }}</b-button 
                    >-->

                      <b-button
                        @click="open_delete_modal(data.item)"
                        v-if="data.item.is_research == true"
                        variant="outline-danger"
                        class="ml-25 px-50 py-25 float-right"
                        size="sm"
                      >
                        <feather-icon icon="Trash2Icon" class="text-danger" />
                      </b-button>
                    </template>
                    <template #cell(Onaylı)="data">
                      <b-form-checkbox
                        @change="set_approved(data.item)"
                        v-model="data.item.Onaylı"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </template>

                    <template #row-details="row">
                      <b-card
                        class="px-0 mx-0"
                        style="max-width: 100vh; overflow-x: auto"
                      >
                        <b-table
                          small
                          bordered
                          :items="Object.values(row.item.phone_log)"
                          :fields="phone_log_header"
                          striped
                        >
                          <template #cell(RecordingFile)="data">
                            <center>
                              <b-button
                                class="ml-25 px-50 py-25"
                                v-if="getVisibleStatus(data.item)"
                                size="sm"
                                @click="openAudio(data.item)"
                                variant="outline-primary"
                              >
                                <feather-icon icon="PlayIcon"></feather-icon>
                              </b-button>
                            </center>
                          </template>
                          <template #cell(Queue)="data">
                            {{
                              queues.find((e) => e.internal_name == data.value)
                                ? queues
                                    .find((e) => e.internal_name == data.value)
                                    .display_name.replace(/ /g, "\u00a0")
                                : data.value.replace(/ /g, "\u00a0")
                            }}
                          </template>
                          <template #cell(Note)="data">
                            <span
                              v-if="
                                crm_permissions.includes('show_note_details')
                              "
                              >{{ data.value }}</span
                            >
                            <b-button
                              v-if="
                                data.value.length > 0 &&
                                !crm_permissions.includes('show_note_details')
                              "
                              size="sm"
                              variant="outline-primary"
                              @click="openNote(data.value)"
                            >
                              Not
                            </b-button>
                          </template>
                          <template #cell(TransferEvent)="data">
                            <center>
                              <feather-icon
                                class="text-danger"
                                v-if="!data.value"
                                icon="XOctagonIcon"
                              >
                              </feather-icon>
                              <feather-icon
                                class="text-success"
                                v-else
                                icon="CheckCircleIcon"
                              >
                              </feather-icon>
                            </center>
                          </template>
                        </b-table>
                        <!-- <b-button
                        @click="
                          () => {
                            f_action.contact_number = row.item.Telefon;
                            modal_finish_code = true;
                          }
                        "
                        class="ml-1"
                        size="sm"
                        variant="outline-primary"
                      >
                        {{ lang("t_addFinishCode") }}
                      </b-button> -->
                      </b-card>
                    </template>
                  </b-table>
                </b-row>
              </b-card>
            </b-tab>
            <!-- PRODUCTS -->
            <b-tab
              v-if="
                product_list.length > 0 && crm_permissions.includes('product')
              "
              :title="lang('t_products')"
            >
              <b-card
                v-if="
                  product_list.length > 0 && crm_permissions.includes('product')
                "
                class="mb-0"
              >
                <b-card-title>
                  <b-button
                    style="width: 8em"
                    @click="
                      modal_product_action = true;
                      selected_product_action = {};
                    "
                    :disabled="selected_item.length == 0"
                    size="sm"
                    variant="gradient-primary"
                    >{{ lang("t_addAction") }}</b-button
                  >
                </b-card-title>
                <b-row class="mx-0" style="display: grid">
                  <b-table
                    style="width: 100%; overflow: auto"
                    bordered
                    small
                    :items="product_list"
                    :fields="product_list_header"
                    striped
                    ref="tableRef"
                    responsive
                  >
                    <template #cell(show_details)="row">
                      <b-button
                        @click="row.toggleDetails"
                        size="sm"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon
                          :icon="
                            !row.detailsShowing
                              ? 'ArrowDownIcon'
                              : 'ArrowUpIcon'
                          "
                        ></feather-icon>
                      </b-button>
                    </template>

                    <template #head(select)>
                      <!-- <b-form-checkbox
                      @change="selectAll"
                      class="custom-control-primary"
                    >
                      <span class="vs-checkbox">
                        <span class="vs-checkbox--check">
                          <i class="vs-icon feather icon-check" />
                        </span>
                      </span>
                    </b-form-checkbox> -->
                    </template>
                    <template #cell(select)="data">
                      <b-form-checkbox
                        @change="selection(data.item)"
                        :checked="
                          selected_item.filter((e) => e == data.item) > 0
                        "
                        v-model="data.item.is_selected"
                        class="custom-control-primary"
                      >
                        <span class="vs-checkbox">
                          <span class="vs-checkbox--check">
                            <i class="vs-icon feather icon-check" />
                          </span>
                        </span>
                      </b-form-checkbox>
                    </template>

                    <template #row-details="row">
                      <!-- {{ row.item.product_logs }} -->
                      <b-card>
                        <b-table
                          v-if="row.item.product_logs"
                          style="max-width: 100vh; overflow-x: auto"
                          small
                          bordered
                          :items="
                            row.item.product_logs ? row.item.product_logs : []
                          "
                          :fields="product_log_header"
                          striped
                          responsive=""
                        >
                        </b-table>

                        <!-- <b-button
                        size="sm"
                        variant="outline-secondary"
                        @click="row.toggleDetails"
                      >
                        {{ lang("t_hide") }}
                      </b-button> -->
                      </b-card>
                    </template>
                  </b-table>
                </b-row>
              </b-card>
            </b-tab>
            <!-- NOTES -->
            <b-tab
              v-if="crm_permissions.includes('notes')"
              :title="lang('t_notes')"
            >
              <b-card
                v-if="crm_permissions.includes('notes')"
                :collapsed="false"
                class="mb-0"
              >
                <b-card-title>
                  <b-button
                    style="width: 8em"
                    @click="modal_add_note = true"
                    size="sm"
                    variant="gradient-primary"
                    >{{ lang("t_addNote") }}</b-button
                  >
                </b-card-title>
                <b-row class="mx-0" style="display: grid">
                  <b-table
                    style="width: 100%; overflow: auto"
                    bordered
                    small
                    :items="note_list"
                    :fields="note_list_header"
                    striped
                    responsive
                    ref="tableNotes"
                  >
                  </b-table>
                </b-row>
              </b-card>
            </b-tab>
            <!-- SMS -->
            <b-tab
              v-if="crm_permissions.includes('sms')"
              :title="lang('t_sms')"
            >
              <b-card v-if="crm_permissions.includes('sms')" class="mb-0">
                <b-row class="mx-0" style="display: grid">
                  <b-table
                    style="width: 100%; overflow: auto"
                    bordered
                    small
                    :items="sms_list"
                    :fields="sms_list_header"
                    striped
                    responsive
                    ref="tableSms"
                  >
                  </b-table>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      @cancel="
        () => {
          p_action.action_date = null;
          p_action.note = '';
        }
      "
      @ok="add_product_action"
      :title="lang('t_addProductAction')"
      v-model="modal_product_action"
      max-width="540"
    >
      <b-form-group :label="lang('t_action')" label-for="finish_code">
        <v-select
          v-model="selected_product_action"
          @input="product_action_change"
          :options="this.finish_codes.filter((e) => e.is_product_finish_code)"
          label="finish_code"
          :placeholder="lang('t_action')"
          :reduce="(val) => val"
        ></v-select>
      </b-form-group>
      <b-form-group
        v-if="selected_product_action.require_date"
        :label="lang('t_actionDate')"
        label-for="action_date"
      >
        <!-- <b-form-datepicker id="action_date" v-model="p_action.action_date" /> -->
        <flat-pickr
          v-model="p_action.action_date"
          class="form-control"
          :title="lang('t_actionDate')"
          :placeholder="lang('t_actionDate')"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            allowInput: true,
            time_24hr: true,
          }"
        />
      </b-form-group>
      <b-form-group :label="lang('t_note')" label-for="note">
        <b-form-textarea rows="5" v-model="p_action.note"></b-form-textarea>
      </b-form-group>
      <!-- <b-form-group
        :label="lang('t_installmentCount')"
        label-for="installment_count"
      >
        <b-form-input
          v-model="p_action.installment_count"
          id="installment_count"
          :placeholder="lang('t_installmentCount')"
          type="number"
        />
      </b-form-group>
      <b-form-group :label="lang('t_installmentAmount')" label-for="payment">
        <b-input-group prepend="₺">
          <b-form-input
            type="number"
            v-model="p_action.installment_payment"
            placeholder="0"
          />
        </b-input-group>
      </b-form-group> -->
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      @ok="save_finish_code"
      :title="lang('t_addFinishCode')"
      v-model="modal_finish_code"
      max-width="540"
    >
      <b-form-group :label="lang('t_addFinishCode')" label-for="finish_code">
        <v-select
          v-model="f_action.finish_code"
          :options="finish_codes.filter((e) => !e.is_product_finish_code)"
          label="finish_code"
          :placeholder="lang('t_addFinishCode')"
          :reduce="(val) => val"
        ></v-select>
      </b-form-group>
      <b-form-group
        v-if="f_action.finish_code && f_action.finish_code.require_date"
        :label="lang('t_actionDate')"
        label-for="action_date"
      >
        <!-- <b-form-datepicker id="action_date" v-model="f_action.action_date" /> -->
        <flat-pickr
          v-model="f_action.action_date"
          class="form-control"
          :title="lang('t_actionDate')"
          :placeholder="lang('t_actionDate')"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            allowInput: true,
            time_24hr: true,
          }"
        />
      </b-form-group>
      <b-form-group :label="lang('t_note')" label-for="note">
        <b-form-textarea
          v-model="f_action.note"
          id="textarea-default"
          :placeholder="lang('t_note')"
          rows="3"
        />
      </b-form-group>
      <b-form-group v-if="f_action.finish_code">
        <b-form-checkbox
          v-model="f_action.finish_code.is_last_finish_code"
          class="custom-control-primary"
          name="check-button"
          switch
        >
          <span> {{ lang("t_lastFinishCode") }}</span>
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      @ok="add_phone"
      :title="lang('t_addPhone')"
      v-model="modal_add_phone"
      max-width="540"
    >
      <b-form-group :label="lang('t_number')" label-for="phone">
        <b-form-input v-model="new_phone" id="phone" placeholder="5XXXXXXXXX" />
      </b-form-group>
      <b-form-group :label="lang('t_relationTo')" label-for="owner">
        <b-form-input
          v-model="new_owner"
          id="owner"
          :placeholder="lang('t_relationTo')"
        />
      </b-form-group>
      <b-form-group :label="lang('t_isApproved')" label-for="is_approved">
        <b-form-checkbox
          v-model="is_approved"
          id="is_approved"
          class="custom-control-primary"
          name="check-button"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      @ok="add_note"
      :title="lang('t_addNote')"
      v-model="modal_add_note"
      max-width="540"
    >
      <b-form-group :label="lang('t_note')" label-for="note">
        <b-form-textarea
          v-model="new_note"
          id="textarea-default"
          :placeholder="lang('t_note')"
          rows="3"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      @ok="add_extra_data"
      centered
      :title="lang('t_addData')"
      v-model="modal_extra_data"
    >
      <b-form-group :label="lang('t_column')" label-for="column">
        <b-form-input
          v-model="customer_extra_data.column"
          id="column"
          :placeholder="lang('t_column')"
        />
      </b-form-group>
      <b-form-group :label="lang('t_value')" label-for="value">
        <b-form-input
          v-model="customer_extra_data.value"
          id="value"
          :placeholder="lang('t_value')"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      size="xl"
      no-close-on-esc
      no-close-on-backdrop
      @ok="update_customer_info"
      centered
      :title="lang('t_customerUpdate')"
      v-model="modal_customer_update"
    >
      <b-card title="Personal">
        <b-row>
          <b-col
            cols="3"
            v-for="(column, i) in Object.keys(tmp_selected_customer).filter(
              (e) =>
                ![
                  'Araç',
                  'Tapu',
                  'SSK',
                  'Mernis',
                  'Muzekkere',
                  'Gsm Sorgu',
                  'TCKN_masked',
                  '_id',
                  'attempts',
                  'total_attempts',
                  'status',
                  'Kuyruk',
                  'create_date',
                  'create_time',
                  'action_date',
                  'action_time',
                  'insert_date',
                  'assign_date',
                ].includes(e)
            )"
            :key="i"
          >
            <b-form-group
              :label="
                column_labels.hasOwnProperty(column)
                  ? column_labels[column]
                  : column
              "
              :label-for="column"
            >
              <b-form-input trim v-model="tmp_selected_customer[column]" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Phones">
        <b-row v-for="(column, i) in tmp_phone_list" :key="i">
          <b-col cols="6">
            <b-form-group :label="'Telefon'" label-for="phone">
              <b-form-input trim v-model="column['Telefon']" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :label="'Sahibi'" label-for="owner">
              <b-form-input trim v-model="column['Sahibi']" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      @ok="send_sms"
      centered
      :title="lang('t_sendSMS')"
      v-model="modal_send_sms"
    >
      <b-row class="mx-0 mb-1">
        <b-form-radio @input="whatsappSelected" value="sms" v-model="sms_type"
          >SMS
        </b-form-radio>
        <b-form-radio
          @change="whatsappSelected"
          class="ml-3"
          value="whatsapp"
          v-model="sms_type"
          >Whatsapp
        </b-form-radio>
      </b-row>

      <b-form-group :label="lang('t_operator')" label-for="operator">
        <v-select
          v-if="sms_type == 'sms'"
          v-model="selected_sms_settings"
          :options="sms_settings"
          label="display_name"
          :placeholder="lang('t_operator')"
          :reduce="(val) => val"
        ></v-select>
        <v-select
          v-else
          v-model="selected_sms_settings"
          :options="wp_providers.filter((e) => e.status)"
          :placeholder="lang('t_whatsapp')"
          :reduce="(val) => val"
          label="display_name"
        >
        </v-select>
      </b-form-group>
      <b-form-group :label="lang('t_smsTemplate')" label-for="operator">
        <v-select
          @input="set_sms_template"
          :options="sms_templates"
          label="display_name"
          :placeholder="lang('t_smsTemplate')"
          :reduce="(val) => val"
        ></v-select>
      </b-form-group>
      <b-form-group :label="lang('t_text')" label-for="message">
        <b-form-textarea
          :disabled="crm_permissions.includes('dont_send_custom_message')"
          v-model="new_sms"
          id="textarea-default"
          :placeholder="lang('t_text')"
          rows="5"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      :title="lang('t_filterTheResults')"
      v-model="modal_filters"
    >
      <flat-pickr
        :key="col.key"
        v-for="col in headers.filter((e) =>
          ['create_date', 'insert_date', 'action_date'].includes(e.key)
        )"
        v-model="filter_db[col.key].data"
        :placeholder="`${col.label}`"
        class="form-control mt-1"
        :config="{ mode: 'range' }"
        @on-change="filterChanged"
      />
      <v-select
        class="mt-1"
        :key="col.key"
        v-for="col in headers.filter((e) =>
          ['finish_code', 'status', 'attempts', 'total_attempts'].includes(
            e.key
          )
        )"
        v-model="filter_db[col.key].data"
        :options="filter_db[col.key].items"
        multiple
        :filterable="false"
        :placeholder="`${col.label}`"
        @input="filterChanged"
      >
        <template #list-header>
          <div class="w-100">
            <b-button
              class="w-50"
              size="sm"
              variant="outline-primary"
              @click="
                () => {
                  filter_db[col.key].data = filter_db[col.key].items;
                  filterChanged();
                }
              "
            >
              <feather-icon size="14" icon="CheckCircleIcon" />
              {{ lang("t_selectAll") }}
            </b-button>
            <b-button
              class="w-50"
              size="sm"
              variant="outline-danger"
              @click="
                () => {
                  filter_db[col.key].data = [];
                }
              "
            >
              <feather-icon size="14" icon="XOctagonIcon" />
              {{ lang("t_cleanSelections") }}
            </b-button>
          </div>
        </template>
      </v-select>
      <v-select
        class="mt-1"
        :key="col.key"
        v-for="col in headers.filter(
          (e) =>
            ![
              'status',
              'finish_code',
              'Kuyruk',
              'create_date',
              'create_time',
              'insert_date',
              'action_date',
              'attempts',
              'total_attempts',
            ].includes(e.key)
        )"
        v-model="filter_db[col.key].data"
        :options="filter_db[col.key].items"
        multiple
        :filterable="false"
        :placeholder="`${col.label} ${lang(
          't_pleaseWriteSomethingToSeeYourOptions'
        )}`"
        @input="filterChanged"
        @search="
          (search, loading) => {
            loading(true);
            filterSearch(search, col.key).then(() => {
              loading(false);
            });
          }
        "
      ></v-select>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      @ok="save_columns"
      centered
      :title="lang('t_columnVisibilityOptions')"
      v-model="modal_column_settings"
    >
      <div style="height: 70vh; overflow-y: auto">
        <b-row class="mx-0" :key="i" v-for="(col, i) in headers">
          <b-col cols="10"> {{ col.label }} {{ lang("t_showHide") }} </b-col>
          <b-col cols="2">
            <span style="font-size: 14px; font-weight: bold" class="mr-1">
              <b-button
                :variant="col.visible ? 'flat-success' : 'flat-danger'"
                class="btn-icon rounded-circle"
                @click="
                  col.visible = !col.visible;
                  !col.visible ? (TableFilters[col.value] = []) : null;
                "
              >
                <feather-icon
                  class="badge-glow"
                  size="18"
                  :icon="col.visible ? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
            </span>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      @ok="assign_agent"
      centered
      :title="lang('t_agentAssign')"
      v-model="modal_assign_agent"
    >
      <!-- selected_assign_type
selected_assign_users -->
      <b-form-group :label="lang('t_assigmnentType')" label-for="assign">
        <v-select
          @input="onChangeSelectedAssignType"
          v-model="selected_assign_type"
          :options="selected_assign_type_options"
          label="display_name"
          :placeholder="lang('t_assigmnentType')"
          :reduce="(val) => val"
        ></v-select>
      </b-form-group>
      <b-form-group :label="lang('t_agents')" label-for="assign_agents">
        <v-select
          :closeOnSelect="false"
          multiple
          v-model="selected_assign_users"
          :options="agents"
          label="short_username"
          :placeholder="lang('t_agents')"
          :reduce="(val) => val.username"
        ></v-select>
      </b-form-group>
      <b-form-group
        v-if="selected_assign_type.internal_name == 'shuffle'"
        :label="''"
        label-for="assign_agents"
      >
        <b-form-checkbox
          v-model="assign_for_product_agent"
          class="custom-control-primary"
          name="check-button"
          switch
        >
          <span> {{ lang("t_assignForProductAgent") }}</span>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="assign_for_product_agent"
        :label="lang('t_columns')"
        label-for="assign_agents"
      >
        <v-select
          v-model="selected_assign_product_fields"
          :options="headers"
          label="label"
          :placeholder="lang('t_columns')"
          :reduce="(val) => val.key"
        ></v-select>
      </b-form-group>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      :title="dynamic_modal_title"
      size="lg"
      hide-footer
      v-model="dynamic_modal"
    >
      <data-upload
        v-if="dynamic_modal_name == 'data_upload'"
        :title="dynamic_modal_title"
      ></data-upload>
      <script-management
        v-if="dynamic_modal_name == 'script_management'"
        :title="dynamic_modal_title"
      ></script-management>
      <vspr
        v-if="dynamic_modal_name == 'data_management'"
        :title="dynamic_modal_title"
      ></vspr>
    </b-modal>
    <b-modal
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      centered
      :title="lang('t_addCustomer')"
      size="lg"
      v-model="modal_add_customer"
      @ok="add_new_customer"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
    >
      <b-card title="Zorunlu Alan">
        <b-form-input
          class="mb-1"
          v-if="new_customer_info && new_customer_info.phones.length > 0"
          v-model="new_customer_info.phones[0].Telefon"
          placeholder="Telefon"
        >
        </b-form-input>
        <v-select
          class="mb-1"
          v-model="new_customer_info['queue']"
          :options="queues"
          label="display_name"
          value="internal_name"
          :placeholder="lang('t_queue')"
          :reduce="(val) => val.internal_name"
        ></v-select>
      </b-card>
      <b-card>
        <b-form-input
          class="mb-1"
          v-model="new_customer_info[col]"
          v-for="(col, i) in columns.filter((e) => !voyce_columns.includes(e))"
          :key="i"
          :placeholder="col"
        >
        </b-form-input>
      </b-card>
    </b-modal>
    <b-modal
      static
      id="modal-color-info"
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="lang('t_info')"
    >
      <b-row class="mt-1">
        <b-col cols="2"
          ><b-button variant="#fff" style="border: 1px solid grey"></b-button
        ></b-col>
        <b-col cols="9"
          ><p>{{ lang("t_crmWhiteColorInfo") }}</p></b-col
        >
      </b-row>
      <b-row class="mt-1">
        <b-col cols="2"><b-button variant="danger"></b-button></b-col>
        <b-col cols="9"
          ><p>{{ lang("t_crmDangerColorInfo") }}</p></b-col
        >
      </b-row>
      <b-row class="mt-1">
        <b-col cols="2"><b-button variant="secondary"></b-button></b-col>
        <b-col cols="9"
          ><p>{{ lang("t_crmSecondaryColorInfo") }}</p></b-col
        >
      </b-row>
      <b-row class="mt-1">
        <b-col cols="2"><b-button variant="info"></b-button></b-col>
        <b-col cols="9"
          ><p>{{ lang("t_crmInfoColorInfo") }}</p></b-col
        >
      </b-row>
      <b-row class="mt-1">
        <b-col cols="2"><b-button variant="warning"></b-button></b-col>
        <b-col cols="9"
          ><p>{{ lang("t_crmWarningColorInfo") }}</p></b-col
        >
      </b-row>
    </b-modal>
    <b-modal
      v-model="modal_special_data"
      static
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="modal_special_data_title"
    >
      <b-table
        :items="extra_data[modal_special_data_title]"
        :fields="extra_data_headers"
      >
      </b-table>
    </b-modal>
    <b-modal
      size="lg"
      v-model="modal_old_notes"
      static
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="lang('t_oldNotes')"
    >
      <b-table responsive="" :items="old_notes"> </b-table>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BContainer,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  VBTooltip,
  BFormSelect,
  BInputGroupAppend,
  BBadge,
  BTable,
  BPagination,
  BInputGroup,
  BListGroupItem,
  BListGroup,
  BFormCheckbox,
  BTabs,
  BTab,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import DataUpload from "./DataUpload.vue";
import ScriptManagement from "./ScriptManagement.vue";
import vspr from "./VSPR.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { $themeColors } from "@themeConfig";
import Cleave from "vue-cleave-component";

export default {
  computed: {
    filter_beautify() {
      console.log("filter_beautify", this.TableFiltersNew[0]);
      try {
        let _tmp = [{}];
        for (const key of Object.keys(this.TableFiltersNew[0])) {
          console.log("aa123", key);
          if (["create_date", "action_date", "insert_date"].includes(key)) {
            if (this.TableFiltersNew[0][key]) {
              console.log("Deneme", key, this.TableFiltersNew[0][key])

              _tmp[0][this.column_labels[key]] = this.TableFiltersNew[0][key];
            }
          }

          if (
            this.TableFiltersNew[0][key] != undefined &&
            this.TableFiltersNew[0][key] != {} &&
            !["create_date", "action_date", "insert_date"].includes(key)
          ) {
            _tmp[0][this.column_labels[key] || key] =
              this.TableFiltersNew[0][key][key];
          }
        }
        return JSON.stringify(_tmp);
      } catch (error) {
        return JSON.stringify([{}]);
      }
    },
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_selected: false,
      dynamic_modal: false,
      dynamic_modal_title: "",
      dynamic_modal_name: "",
      product_actions: [],
      sms_templates: [],
      old_notes: [],
      modal_old_notes: false,
      modal_product_action: false,
      modal_add_phone: false,
      modal_add_note: false,
      modal_send_sms: false,
      modal_extra_data: false,
      modal_customer_update: false,
      modal_finish_code: false,
      modal_column_settings: false,
      modal_filters: false,
      modal_assign_agent: false,
      modal_add_customer: false,
      modal_special_data: false,
      modal_special_data_title: "",
      extra_data_headers: [
        { key: "Tarih", label: "Tarih", sortable: true },
        { key: "Detay", label: "Detay", sortable: true },
      ],
      perPage: 50,
      pageOptions: [10, 25, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      new_owner: "",
      is_approved: "",
      new_phone: "",
      new_note: "",
      new_sms: "",
      sms_type: "sms",
      is_manager: false,
      selected_sms_settings: {},
      sms_settings: [],
      projects: [],
      queues: [],
      agents: [],
      finish_codes: [],
      selected_assign_type: {
        internal_name: "shuffle",
        display_name: `${globalThis._lang("t_shuffle")}`,
      },
      selected_assign_type_options: [
        {
          internal_name: "pool",
          display_name: `${globalThis._lang("t_contactPool")}`,
        },
        {
          internal_name: "shuffle",
          display_name: `${globalThis._lang("t_shuffle")}`,
        },
      ],
      extra_data: [],
      selected_assign_users: [],
      assign_for_product_agent: false,
      selected_assign_product_fields: '',
      selected_shuffle_users: [],
      selected_shuffle_data: [],
      customer_extra_info: [],
      customer_extra_data: {
        column: "",
        value: "",
      },
      f_action: {
        contact_id: "",
        finish_code: "",
        contact_number: "",
        note: "",
        last_action: false,
        action_date: "",
        call_direction: "",
      },
      p_action: {
        action: "",
        action_date: "",
        note: "",
        // installment_count: "",
        // installment_payment: "",
      },
      selected_product_action: {},
      selected_customer: {},
      t_colors: [$themeColors.success],
      colors: ["primary", "warning", "success", "danger"],
      selected_item: [],
      product_list: [],
      product_list_header: [
        { key: "show_details", label: "" },
        { key: "select", label: "" },
      ],
      fields: [
        // { key: '_id', label: 'Id' },
        { key: "Müşteri No", label: "Müşteri No", sortable: true },
        { key: "Adı Soyadı", label: "Adı Soyadı", sortable: true },
        { key: "TCKN", label: "TCKN", sortable: true },
        { key: "Kuyruk", label: "Kuyruk", sortable: true },
        { key: "Adres", label: "Adres", sortable: true },
        { key: "Email", label: "Email", sortable: true },
        { key: "status", label: "status", sortable: true },
        { key: "assigned_agent", label: "assigned_agent", sortable: true },
        { key: "ex_agent", label: "ex_agent", sortable: true },
        { key: "assign_date", label: "assign_date", sortable: true },
        { key: "finish_code", label: "finish_code", sortable: true },
        { key: "action_date", label: "action_date", sortable: true },
        { key: "insert_date", label: "insert_date", sortable: true },
        { key: "create_date", label: "create_date", sortable: true },
      ],

      phone_list: [],
      phone_list_header: [
        { key: "show_details", label: "" },
        {
          label: `${globalThis._lang("t_number")}`,
          sortable: true,
          key: "Telefon",
        },
        {
          label: `${globalThis._lang("t_relationTo")}`,
          sortable: true,
          key: "Sahibi",
        },
        {
          label: `${globalThis._lang("t_status")}`,
          sortable: true,
          key: "status",
        },
        {
          label: `${globalThis._lang("t_attempts")}`,
          sortable: true,
          key: "attempts",
        },
      ],
      note_list: [],
      note_list_header: [
        {
          label: `${globalThis._lang("t_date")}`,
          sortable: true,
          key: "insert_date",
        },
        {
          label: `${globalThis._lang("t_agent")}`,
          sortable: true,
          key: "agent",
        },
        {
          label: `${globalThis._lang("t_note")}`,
          sortable: true,
          key: "note",
        },
      ],
      sms_list: [],
      sms_list_header: [
        {
          label: `${globalThis._lang("t_date")}`,
          sortable: true,
          key: "Tarih",
        },
        {
          label: `${globalThis._lang("t_sender")}`,
          sortable: true,
          key: "Gönderen",
        },
        {
          label: `${globalThis._lang("t_number")}`,
          sortable: true,
          key: "Telefon",
        },
        {
          label: `${globalThis._lang("t_text")}`,
          sortable: true,
          key: "Mesaj",
        },
      ],
      phone_log_header: [
        {
          label: `${globalThis
            ._lang("t_recordingFile")
            .replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "RecordingFile",
        },
        {
          label: `${globalThis
            ._lang("t_progresingDate")
            .replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "StartTime",
        },
        {
          label: `${globalThis._lang("t_agent").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Agent",
        },
        {
          label: `${globalThis._lang("t_finishCode").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Verdict",
        },
        {
          label: `${globalThis._lang("t_queue").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Queue",
        },
        {
          label: `${globalThis._lang("t_transfer").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "TransferEvent",
        },
        {
          label: `${globalThis._lang("t_note").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Note",
        },
        {
          label: `${globalThis._lang("t_actionDate2").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "ActionDate",
        },
        {
          label: `${globalThis._lang("t_direction").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Direction",
          class: "headerFont",
        },
      ],
      product_log_header: [
        {
          label: `${globalThis._lang("t_date").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "insert_date",
        },
        {
          label: `${globalThis._lang("t_agent").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "agent",
        },
        {
          label: `${globalThis._lang("t_action").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "action",
        },
        {
          label: `${globalThis
            ._lang("t_progresingDate")
            .replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "action_date",
        },
        {
          label: `${globalThis
            ._lang("t_note")
            .replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "note",
        },
        // {
        //   label: `${globalThis._lang("t_installmentCount").replace(/ /g, '\u00a0')}`,
        //   sortable: true,
        //   key: "installment_count",
        // },
        // {
        //   label: `${globalThis._lang("t_installmentAmount").replace(/ /g, '\u00a0')}`,
        //   sortable: true,
        //   key: "installment_payment",
        // },
      ],
      old_notes_fields: [
        { label: "Notu Alan Agent", key: "Notu Alan Agent", sortable: true },
        { label: "Kayıt Tarihi", key: "Kayıt Tarihi", sortable: true },
        { label: "Kurum Adı", key: "Kurum Adı", sortable: true },
        { label: "Föy No", key: "Föy No", sortable: true },
        { label: "Hukuki Durumu", key: "Hukuki Durumu", sortable: true },
        {
          label: "Gelişme Sonucu(Güncel)",
          key: "Gelişme Sonucu(Güncel)",
          sortable: true,
        },
        {
          label: "Gelişme Sonucu (Gelişme Tarihi)",
          key: "Gelişme Sonucu (Gelişme Tarihi)",
          sortable: true,
        },
        { label: "Not Tipi", key: "Not Tipi", sortable: true },
        { label: "Telefon", key: "Telefon", sortable: true },
        { label: "Görüşme Notu", key: "Görüşme Notu", sortable: true },
        { label: "Föye Atanan Agent", key: "Föye Atanan Agent", sortable: true },
      ],

      is_progress: false,
      call_list_items: [{ internal_name: "call_list", display_name: globalThis._lang("t_callList") }, { internal_name: "pool", display_name: globalThis._lang("t_pool") }],
      selected_call_list: { internal_name: "call_list", display_name: globalThis._lang("t_callList") },
      voyce_columns: [
        "Kuyruk",
        "TCKN_masked",
        "_id",
        "active",
        "assign_date",
        "assigned_agent",
        "ex_agent",
        "cycles",
        "notes",
        "sms",
        "extra_data",
        "phones",
        "products",
        "create_date",
        "action_date",
        "finish_code",
        "campaign",
        "status",
        "attempts",
        "insert_date",
        "total_attempts",
        "assigned_agent2",
      ],
      default_columns: [
        "Müşteri No",
        "Adı Soyadı",
        "TCKN",
        "Kuyruk",
        "Email",
        "Adres",
      ],
      filter_columns: [],
      columns: [],
      selected_column: "Müşteri No",
      new_customer_info: {
        ["queue"]: "",
        phones: [{ Telefon: "" }],
      },
      get_customer_page: 1,
      get_customer_search_term: "",
      get_customer_sort: globalThis.env.CRM_SORT_COLUMN ?? 'insert_date',
      get_customer_sort_by: "asc",
      get_customer_total_record: 0,
      get_customer_record_per_page: "15",
      TableFiltersNew: [],
      TableFilters: {},
      EmptyTableFilters: {},
      filtered_customer_count: 0,
      saved_headers: [],
      headers: [],
      customer_list: [],
      column_labels: {
        status: globalThis._lang('t_status'),
        assigned_agent: globalThis._lang('t_agent'),
        attempts: globalThis._lang('t_attempts'),
        total_attempts: globalThis._lang('t_totalAttempts'),
        campaign: globalThis._lang('t_campaign'),
        finish_code: globalThis._lang('t_finishCode'),
        insert_date: globalThis._lang('t_insertDate'),
        action_date: globalThis._lang('t_actionDate'),
        action_time: globalThis._lang('t_actionTime'),
        create_date: globalThis._lang('t_createDate'),
        create_time: globalThis._lang('t_createTime'),
        ex_agent: globalThis._lang('t_exAgent'),
        assign_date: globalThis._lang('t_assignDate'),
      },
      filterSearchInterval: {},
      filter_db: {
        // ["Adı Soyadı"]: {
        //   data: [],
        //   loading: false,
        //   items: [],
        // },
        status: {
          data: [],
          loading: false,
          items: [],
        },
        ["Kuyruk"]: {
          data: [],
          loading: false,
          items: [],
        },
        finish_code: {
          data: [],
          loading: false,
          items: [],
        },
        total_attempts: {
          data: [],
          loading: false,
          items: [],
        },
        attempts: {
          data: [],
          loading: false,
          items: [],
        },
      },

      insert_date_modal: false,
      action_date_modal: false,
      create_date_modal: false,

      filter_db_empty: {
        ["Adı Soyadı"]: {
          data: [],
          loading: false,
          items: [],
        },
        ["Müşteri No"]: {
          data: [],
          loading: false,
          items: [],
        },
        assigned_agent: {
          data: [],
          loading: false,
          items: [],
        },
        insert_date: {
          data: [],
          loading: false,
          items: [],
        },
        action_date: {
          data: [],
          loading: false,
          items: [],
        },
        create_date: {
          data: [],
          loading: false,
          items: [],
        },
        status: {
          data: [],
          loading: false,
          items: [],
        },
        ex_agent: {
          data: [],
          loading: false,
          items: [],
        },
        ["Kuyruk"]: {
          data: [],
          loading: false,
          items: [],
        },
        finish_code: {
          data: [],
          loading: false,
          items: [],
        },
        total_attempts: {
          data: [],
          loading: false,
          items: [],
        },
        attempts: {
          data: [],
          loading: false,
          items: [],
        },
      },
      script: "",
      script_tmp: "",
      wp_providers: [],
      crm_permissions: [],
      other_permissions: [],
      tmp_selected_customer: "",
      tmp_phone_list: "",
      selected_audio: "",
      htmlcontent: "",
      audio_modal: false,
    };
  },
  components: {
    BSpinner,
    BFormRadio,
    BContainer,
    Cleave,
    BFormDatepicker,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardActions,
    DataUpload,
    ScriptManagement,
    vspr,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BInputGroupAppend,
    BBadge,
    BTable,
    BPagination,
    BInputGroup,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormInput,
    BTabs,
    BTab,
    BModal,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    flatPickr,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    getTCKN() {
      if (this.crm_permissions.includes('show_tckn')) {
        return 'TCKN';
      }
      return 'TCKN_masked';
    },
    onChangeSelectedAssignType() {
      if (this.selected_assign_type.internal_name == 'pool') {
        this.assign_for_product_agent = false;
        this.selected_assign_product_fields = '';
      }
    },

    whatsappSelected() {
      console.log(this.sms_type, this.wp_providers.filter((e) => e.status)[0])
      if (this.sms_type == 'whatsapp') {
        this.selected_sms_settings = this.wp_providers.filter((e) => e.status)[0];
      } else {
        this.selected_sms_settings = '';
      }
    },
    get_wp_providers: async function () {
      var response = (
        await this.$http_in.get(`text-channel-wp/v1/WhatsappProvider`)
      ).data;
      console.log("WhatsappProvider", response);
      this.wp_providers = response.filter(e => globalThis.user.selected_queues.includes(e.queue));
    },
    maskPhoneNumber(phoneNumber) {
      var maskedNumber = phoneNumber.slice(0, -2).replace(/./g, '*') + phoneNumber.slice(-2);
      return maskedNumber;
    },
    getVisibleStatus(item) {
      if (this.crm_permissions.includes("crm_display_sound")) {
        return true;
      }

      if (this.other_permissions.includes("agent_display_sound") && item.Agent == globalThis.user.username) {
        return true;
      }

      return false;


    },
    openAudio(item) {
      console.log(item);

      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }
      this.htmlcontent = `<audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("agent_download_sound")
        ? ""
        : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
                <source src="${this.selected_audio}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>`;
      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.Verdict}</span><br><span class="font-weight-bolder">  ${item.BaseRemoteNumber}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },

    async next_customer() {
      await this.getCustomersNext();
      this.customer_list = this.customer_list.filter(
        (e) => e["Müşteri No"] != this.selected_customer["Müşteri No"]
      );
      await this.selectCustomer(this.customer_list[0]);
    },
    open_modal_special_data: async function (type) {
      this.modal_special_data_title = type;
      this.modal_special_data = true;
    },
    openUpdateCustomerModal() {
      this.modal_customer_update = true;
      this.tmp_selected_customer = JSON.parse(
        JSON.stringify(this.selected_customer)
      );
      this.tmp_phone_list = JSON.parse(JSON.stringify(this.phone_list));
    },
    open_add_customer() {
      this.modal_add_customer = true;
      this.reset_customer_info();
      this.new_customer_info.phones[0].Telefon =
        globalThis.LayoutContentRendererDefault.current_call_info != null
          ? globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.substr(
            -10
          )
          : "";
    },
    openNote(note) {
      this.$swal({
        html: note,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
      });
    },
    start_preview_call(phone) {
      console.log(phone);
      console.log(this.selected_customer._id);
      globalThis.LayoutContentRendererDefault.preview_call_request(phone, this.selected_customer._id);
    },
    selection(item) {
      if (!this.selected_item.includes(item)) {
        this.selected_item.push(item);
      } else {
        console.log("else");
        this.selected_item = this.selected_item.filter((x) => x != item);
      }
    },
    product_action_change(item) {
      this.p_action.action = item.finish_code;
    },

    rowClass(item, type) {
      // console.log("item", item);
      // if (!item || type !== 'row') return
      if (item.status === "C" && item.total_attempts == 0) return "";
      if (item.status === "K" && item.total_attempts == 0)
        return "table-danger table-style";
      if (item.status === "K" && item.total_attempts > 0)
        return "table-secondary table-style";
      if (item.status === "C" && item.total_attempts > 0)
        return "table-info table-style";
      if (item.status === "U") return "table-warning table-style";
    },
    rowClassPhone(item, type) {
      // console.log("item", item);
      // if (!item || type !== 'row') return
      if (item.status === "P") return "table-danger table-style";
    },
    selectAll() {
      if (this.selected_item.length == 0) {
        for (const item of this.product_list) {
          item.is_selected = true;
        }
        this.selected_item = [...this.product_list];
      } else {
        this.selected_item = [];
        for (const item of this.product_list) {
          item.is_selected = false;
        }
      }
    },
    checked(index, checked, selectAll) {
      let tableRef = this.$refs.tableRef;

      if (selectAll) {
        tableRef.selectAllRows();
        return;
      }
      console.log("cc", index, checked);
      // to see all availabe table properties just do a console.log(tableRef)
      if (checked === false) {
        tableRef.selectRow(index);
      } else {
        tableRef.unselectRow(index);
      }
    },

    on_management_menu_item_clicked(item_name) {
      console.log(item_name);
      this.dynamic_modal_name = item_name;
      switch (item_name) {
        case "data_upload":
          this.dynamic_modal_title = globalThis._lang("t_dataUpload");
          break;
        case "data_management":
          this.dynamic_modal_title = globalThis._lang("t_dataManagement");
          break;
        case "script_management":
          this.dynamic_modal_title = globalThis._lang("t_scriptManagement");
          break;

        default:
          break;
      }
      this.dynamic_modal = true;
    },
    getCustomerBySearch: async function () {
      this.is_progress = true;

      if (
        this.get_customer_search_term.trim().length > 0 &&
        this.get_customer_search_term.trim().length < 3
      ) {
        this.is_progress = false;
        return;
      }

      this.get_customer_page = 1;
      let page_page = 1;
      console.log("this.headers.length", this.headers.length);
      let all_filters = {};
      // if (this.headers.length > 0) {
      //   all_filters = {
      //     ["Adı Soyadı"]:
      //       this.filter_db["Adı Soyadı"].data.length > 0
      //         ? this.filter_db["Adı Soyadı"].data.join("|")
      //         : "",
      //     ["Müşteri No"]:
      //       this.filter_db["Müşteri No"].data.length > 0
      //         ? this.filter_db["Müşteri No"].data.join("|")
      //         : "",
      //     assigned_agent:
      //       this.filter_db.assigned_agent.data.length > 0
      //         ? this.filter_db.assigned_agent.data.join("|")
      //         : "",
      //     insert_date:
      //       this.filter_db.insert_date.data.length > 0
      //         ? this.filter_db.insert_date.data.join("|")
      //         : "",
      //     action_date:
      //       this.filter_db.action_date.data.length > 0
      //         ? this.filter_db.action_date.data.join("|")
      //         : "",
      //     create_date:
      //       this.filter_db.create_date.data.length > 0
      //         ? this.filter_db.create_date.data.join("|")
      //         : "",
      //     status:
      //       this.filter_db.status.data.length > 0
      //         ? this.filter_db.status.data.join("|")
      //         : "",
      //     ex_agent:
      //       this.filter_db.ex_agent.data.length > 0
      //         ? this.filter_db.ex_agent.data.join("|")
      //         : "",
      //     ["Kuyruk"]:
      //       this.filter_db["Kuyruk"].data.length > 0
      //         ? this.filter_db["Kuyruk"].data.join("|")
      //         : "",
      //     finish_code:
      //       this.filter_db.finish_code.data.length > 0
      //         ? this.filter_db.finish_code.data.join("|")
      //         : "",
      //   };
      // }

      if (this.headers.length > 0) {
        const filterKeys = [
          "Adı Soyadı",
          "Müşteri No",
          "assigned_agent",
          "insert_date",
          "action_date",
          "create_date",
          "status",
          "ex_agent",
          "Kuyruk",
          "finish_code"
        ];

        filterKeys.forEach(key => {
          if (this.filter_db[key] && this.filter_db[key].data && this.filter_db[key].data.length > 0) {
            all_filters[key] = this.filter_db[key].data.join("|");
          } else {
            all_filters[key] = "";
          }
        });
      }
      var response = (
        await this.$http_in.post(`crm/v1/Customers`, {
          page: page_page,
          searchTerm: this.get_customer_search_term,
          sort: this.get_customer_sort,
          sortBy: this.get_customer_sort_by,
          limit: this.get_customer_record_per_page,
          filters: JSON.stringify(all_filters),
          justShowOwnCustomers: this.crm_permissions.includes('just_show_own_customers')
        })
      ).data;
      this.get_customer_total_record = response.count;
      this.headers = [];
      this.TableFilters = {};
      for (const row of response.result) {
        row["insert_date"] = [null, undefined, "-1"].includes(
          row["insert_date"]
        )
          ? " "
          : row["insert_date"].substr(0, 10);
        row["action_date"] = [null, undefined, "-1"].includes(
          row["action_date"]
        )
          ? " "
          : row["action_date"].substr(0, 10);
        row["create_date"] = [null, undefined, "-1"].includes(
          row["create_date"]
        )
          ? " "
          : row["create_date"].substr(0, 10);
        for (const key of Object.keys(row)) {
          // if (!['insert_date', 'action_date'].includes(key)) {
          row[key] = [null, undefined].includes(row[key]) ? " " : row[key];
          // }
        }
        // console.log(row);
      }
      this.customer_list = [...response.result];
      this.filtered_customer_count = this.customer_list.length;
      for (const item of this.columns) {
        if (
          [
            "_id",
            "campaign",
            "assign_agent",
            "phones",
            "products",
            "notes",
            "active",
            "cycles",
            "extra_data",
            "view_moment",
            "sms",
            "TCKN_masked",
          ].includes(item)
        ) {
          continue;
        }
        this.TableFilters[item] = [];
        this.EmptyTableFilters[item] = [];
        if (
          !Object.keys(this.filter_db).includes(
            item == 'TCKN' ? this.getTCKN() : item
          )
        ) {
          this.filter_db[item == 'TCKN' ? this.getTCKN() : item] = {
            data: [],
            loading: false,
            items: [],
          };
        }
        // this.headers.push({
        //   label:
        //     this.column_labels[item] == undefined
        //       ? item
        //       : this.column_labels[item],
        //   align: "left",
        //   sortable: true,
        //   customFilterable: true,
        //   key: item=='TCKN'?this.getTCKN():item,
        //   class: "headerFont",
        //   visible: (
        //     this.saved_headers.find((e) => e.key == item) || { visible: true }
        //   ).visible
        //     ? true
        //     : false,
        // });
        this.headers.push({
          label:
            this.column_labels[item] == undefined
              ? item.replace(/ /g, "\u00a0")
              : this.column_labels[item].replace(/ /g, "\u00a0"),
          align: "left",
          sortable: true,
          customFilterable: true,
          key: item == 'TCKN' ? this.getTCKN() : item,
          class: "headerFont",
          visible: (
            this.saved_headers.find((e) => e.key == item) || { visible: true }
          ).visible
            ? true
            : false,
        });

        for (const row of this.customer_list) {
          for (const column of this.headers) {
            // if (column.key != "finish_code") {
            row[column.key] =
              typeof row[column.key] == "string"
                ? row[column.key].split("-").join(".").replace(/ /g, "\u00a0")
                : row[column.key];
            // }
          }
        }
        console.log("headers", this.headers);

        this.headers = this.headers.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
      }
      this.TableFilters = { ...this.TableFilters };
      this.EmptyTableFilters = { ...this.EmptyTableFilters };
      this.is_progress = false;
    },
    getCustomers: async function () {
      this.is_progress = true;
      let page_page = this.get_customer_page;

      this.insert_date_modal = false;
      this.action_date_modal = false;
      this.create_date_modal = false;

      let all_filters = {};
      if (this.headers.length > 0) {
        let date_format = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format["insert_date"] = this.filter_db.insert_date.data.includes(
            " to "
          )
            ? this.filter_db.insert_date.data.split(" to ").join("|")
            : this.filter_db.insert_date.data +
            "|" +
            this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format["create_date"] = this.filter_db.create_date.data.includes(
            " to "
          )
            ? this.filter_db.create_date.data.split(" to ").join("|")
            : this.filter_db.create_date.data +
            "|" +
            this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format["action_date"] = this.filter_db.action_date.data.includes(
            " to "
          )
            ? this.filter_db.action_date.data.split(" to ").join("|")
            : this.filter_db.action_date.data +
            "|" +
            this.filter_db.action_date.data;
        }
        let date_format2 = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format2["insert_date"] =
            this.filter_db.insert_date.data.includes(" to ")
              ? this.filter_db.insert_date.data.split(" to ").join(",")
              : this.filter_db.insert_date.data +
              "," +
              this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format2["create_date"] =
            this.filter_db.create_date.data.includes(" to ")
              ? this.filter_db.create_date.data.split(" to ").join(",")
              : this.filter_db.create_date.data +
              "," +
              this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format2["action_date"] =
            this.filter_db.action_date.data.includes(" to ")
              ? this.filter_db.action_date.data.split(" to ").join(",")
              : this.filter_db.action_date.data +
              "," +
              this.filter_db.action_date.data;
        }
        console.log("date_format", date_format);

        console.log("geldi");
        all_filters = {};
        for (const col of this.headers) {
          if (["insert_date", "action_date", "create_date"].includes(col.key)) {
            all_filters[col.key] = date_format[col.key];
          } else {
            all_filters[col.key] =
              this.filter_db[col.key].data.length > 0
                ? this.filter_db[col.key].data.join("|")
                : "";
          }
        }


        this.TableFiltersNew = [{}];
        for (const col of this.headers) {
          if (["insert_date", "action_date", "create_date"].includes(col.key)) {
            this.TableFiltersNew[0][col.key] = date_format2[col.key];
          } else {
            this.TableFiltersNew[0][col.key] = {
              ...(this.filter_db[col.key].data.length > 0 && {
                [col.key]:
                  this.filter_db[col.key].data.length > 0
                    ? this.filter_db[col.key].data.join(",")
                    : "",
              }),
            };
          }
        }
      }

      console.log("test");
      var response = (
        await this.$http_in.post(`crm/v1/Customers`, {
          collection: `${globalThis.user.selected_project}_customers`,
          list_type: this.selected_call_list.internal_name,
          page: page_page,
          searchTerm: this.get_customer_search_term,
          sort: this.get_customer_sort,
          sortBy: this.get_customer_sort_by,
          limit: this.get_customer_record_per_page,
          filters: JSON.stringify(all_filters),
        })
      ).data;
      console.log("response", response);
      this.get_customer_total_record = response.count;
      this.headers = [];
      this.TableFilters = {};

      for (const row of response.result) {
        row["insert_date"] = [null, undefined, "-1"].includes(
          row["insert_date"]
        )
          ? " "
          : row["insert_date"].substr(0, 10);
        row["action_date"] = [null, undefined, "-1"].includes(
          row["action_date"]
        )
          ? " "
          : row["action_date"].substr(0, 10);
        row["create_date"] = [null, undefined, "-1"].includes(
          row["create_date"]
        )
          ? " "
          : row["create_date"].substr(0, 10);
        for (const key of Object.keys(row)) {
          // if (!['insert_date', 'action_date'].includes(key)) {
          row[key] = [null, undefined].includes(row[key]) ? " " : row[key];
          // }
        }
        // console.log(row);
      }
      this.customer_list = [...response.result];

      this.filtered_customer_count = this.customer_list.length;
      for (const item of this.columns) {
        if (
          [
            "_id",
            "campaign",
            "assign_agent",
            "phones",
            "products",
            "notes",
            "active",
            "cycles",
            "extra_data",
            "sms",
            "TCKN_masked",
          ].includes(item)
        ) {
          continue;
        }
        this.TableFilters[item] = [];
        this.EmptyTableFilters[item] = [];

        if (
          !Object.keys(this.filter_db).includes(
            item == 'TCKN' ? this.getTCKN() : item
          )
        ) {
          this.filter_db[item == 'TCKN' ? this.getTCKN() : item] = {
            data: [],
            loading: false,
            items: [],
          };
        }

        this.headers.push({
          label:
            this.column_labels[item] == undefined
              ? item.replace(/ /g, "\u00a0")
              : this.column_labels[item].replace(/ /g, "\u00a0"),
          align: "left",
          sortable: true,
          customFilterable: true,
          key: item == 'TCKN' ? this.getTCKN() : item,
          class: "headerFont",
          visible: (
            this.saved_headers.find((e) => e.key == item) || { visible: true }
          ).visible
            ? true
            : false,
        });

        for (const row of this.customer_list) {
          for (const column of this.headers) {
            // if (column.key != "finish_code") {
            row[column.key] =
              typeof row[column.key] == "string"
                ? row[column.key].split("-").join(".").replace(/ /g, "\u00a0")
                : row[column.key];
            // }
          }
        }
        console.log("headers", this.headers);

        console.log("this.filter_db", this.filter_db);

        this.headers = this.headers.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
      }
      console.log("headers", this.headers);
      this.TableFilters = { ...this.TableFilters };
      this.EmptyTableFilters = { ...this.EmptyTableFilters };
      this.is_progress = false;
    },
    getCustomersNext: async function () {
      this.is_progress = true;
      let page_page = this.get_customer_page;

      this.insert_date_modal = false;
      this.action_date_modal = false;
      this.create_date_modal = false;

      let all_filters = {};
      if (this.headers.length > 0) {
        let date_format = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format["insert_date"] = this.filter_db.insert_date.data.includes(
            " to "
          )
            ? this.filter_db.insert_date.data.split(" to ").join("|")
            : this.filter_db.insert_date.data +
            "|" +
            this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format["create_date"] = this.filter_db.create_date.data.includes(
            " to "
          )
            ? this.filter_db.create_date.data.split(" to ").join("|")
            : this.filter_db.create_date.data +
            "|" +
            this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format["action_date"] = this.filter_db.action_date.data.includes(
            " to "
          )
            ? this.filter_db.action_date.data.split(" to ").join("|")
            : this.filter_db.action_date.data +
            "|" +
            this.filter_db.action_date.data;
        }
        let date_format2 = {};
        if (this.filter_db.insert_date.data.length > 0) {
          date_format2["insert_date"] =
            this.filter_db.insert_date.data.includes(" to ")
              ? this.filter_db.insert_date.data.split(" to ").join(",")
              : this.filter_db.insert_date.data +
              "," +
              this.filter_db.insert_date.data;
        }
        if (this.filter_db.create_date.data.length > 0) {
          date_format2["create_date"] =
            this.filter_db.create_date.data.includes(" to ")
              ? this.filter_db.create_date.data.split(" to ").join(",")
              : this.filter_db.create_date.data +
              "," +
              this.filter_db.create_date.data;
        }
        if (this.filter_db.action_date.data.length > 0) {
          date_format2["action_date"] =
            this.filter_db.action_date.data.includes(" to ")
              ? this.filter_db.action_date.data.split(" to ").join(",")
              : this.filter_db.action_date.data +
              "," +
              this.filter_db.action_date.data;
        }
        console.log("date_format", date_format);

        console.log("geldi");
        all_filters = {};
        for (const col of this.headers) {
          if (["insert_date", "action_date", "create_date"].includes(col.key)) {
            all_filters[col.key] = date_format[col.key];
          } else {
            all_filters[col.key] =
              this.filter_db[col.key].data.length > 0
                ? this.filter_db[col.key].data.join("|")
                : "";
          }
        }


        this.TableFiltersNew = [{}];
        for (const col of this.headers) {
          if (["insert_date", "action_date", "create_date"].includes(col.key)) {
            this.TableFiltersNew[0][col.key] = date_format2[col.key];
          } else {
            this.TableFiltersNew[0][col.key] = {
              ...(this.filter_db[col.key].data.length > 0 && {
                [col.key]:
                  this.filter_db[col.key].data.length > 0
                    ? this.filter_db[col.key].data.join(",")
                    : "",
              }),
            };
          }
        }
      }

      console.log("test");
      var response = (
        await this.$http_in.post(`crm/v1/Customers`, {
          collection: `${globalThis.user.selected_project}_customers`,
          list_type: this.selected_call_list.internal_name,
          page: page_page,
          searchTerm: this.get_customer_search_term,
          sort: globalThis.env.CRM_SORT_COLUMN ?? 'insert_date',
          sortBy: this.get_customer_sort_by,
          limit: this.get_customer_record_per_page,
          filters: JSON.stringify(all_filters),
        })
      ).data;
      console.log("response", response);
      this.get_customer_total_record = response.count;
      this.headers = [];
      this.TableFilters = {};

      for (const row of response.result) {
        row["insert_date"] = [null, undefined, "-1"].includes(
          row["insert_date"]
        )
          ? " "
          : row["insert_date"].substr(0, 10);
        row["action_date"] = [null, undefined, "-1"].includes(
          row["action_date"]
        )
          ? " "
          : row["action_date"].substr(0, 10);
        row["create_date"] = [null, undefined, "-1"].includes(
          row["create_date"]
        )
          ? " "
          : row["create_date"].substr(0, 10);
        for (const key of Object.keys(row)) {
          // if (!['insert_date', 'action_date'].includes(key)) {
          row[key] = [null, undefined].includes(row[key]) ? " " : row[key];
          // }
        }
        // console.log(row);
      }
      this.customer_list = [...response.result];

      this.filtered_customer_count = this.customer_list.length;
      for (const item of this.columns) {
        if (
          [
            "_id",
            "campaign",
            "assign_agent",
            "phones",
            "products",
            "notes",
            "active",
            "cycles",
            "extra_data",
            "sms",
            "TCKN_masked",
          ].includes(item)
        ) {
          continue;
        }
        this.TableFilters[item] = [];
        this.EmptyTableFilters[item] = [];

        if (
          !Object.keys(this.filter_db).includes(
            item == 'TCKN' ? this.getTCKN() : item
          )
        ) {
          this.filter_db[item == 'TCKN' ? this.getTCKN() : item] = {
            data: [],
            loading: false,
            items: [],
          };
        }

        this.headers.push({
          label:
            this.column_labels[item] == undefined
              ? item.replace(/ /g, "\u00a0")
              : this.column_labels[item].replace(/ /g, "\u00a0"),
          align: "left",
          sortable: true,
          customFilterable: true,
          key: item == 'TCKN' ? this.getTCKN() : item,
          class: "headerFont",
          visible: (
            this.saved_headers.find((e) => e.key == item) || { visible: true }
          ).visible
            ? true
            : false,
        });

        for (const row of this.customer_list) {
          for (const column of this.headers) {
            // if (column.key != "finish_code") {
            row[column.key] =
              typeof row[column.key] == "string"
                ? row[column.key].split("-").join(".").replace(/ /g, "\u00a0")
                : row[column.key];
            // }
          }
        }
        console.log("headers", this.headers);

        console.log("this.filter_db", this.filter_db);

        this.headers = this.headers.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
      }
      console.log("headers", this.headers);
      this.TableFilters = { ...this.TableFilters };
      this.EmptyTableFilters = { ...this.EmptyTableFilters };
      this.is_progress = false;
    },
    getCustomerColumns: async function () {
      // //console.log('this.agent.Project.INTERNAL_NAME', this.agent);
      var response = (await this.$http_in.get(`crm/v1/CustomerColumns`)).data;
      this.filter_columns = [];
      console.log("coll", response);
      this.columns = response[0].allkeys.sort();
      this.reset_customer_info();
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "notes",
            "phones",
            "products",
            "attempts",
            "campaign",
          ].includes(item)
        ) {
          continue;
        }
        this.filter_columns.push({
          text:
            this.column_labels[item] == undefined
              ? item
              : this.column_labels[item],
          value: item,
        });
      }

      this.selected_column = "Müşteri No";
      // this.selected_sort = this.columns[0];
      //console.log("columns", response);
    },
    GetFinishCodes: async function () {
      var response = (await this.$http_in.get(`crm/v1/FinishCodes`)).data;
      this.finish_codes = [];
      let fc = globalThis.permissions["finish_code"]
      // console.log("this.$store.state.privileges", this.$store.state.privileges);
      for (const row of response.finish_codes) {
        // console.log(row);
        if (fc.includes(row['finish_code'])) {
          this.finish_codes.push(row);
        }
      }

      // this.finish_codes = response.finish_codes;
    },
    reset_customer_info: function () {
      this.new_customer_info = {
        ["queue"]: "",
        phones: [{ Telefon: "" }],
      };

      for (const item of this.columns) {
        if (item == "_id") {
          continue;
        }
        this.new_customer_info[item] = "";
        if (item == "queue") {
          this.new_customer_info["queue"] = "";
        }
        if (item == "finish_code") {
          this.new_customer_info[item] = "-";
        }
        if (item == "phones") {
          this.new_customer_info[item] = [
            {
              Telefon: "",
              status: "C",
              attempts: 0,
              total_attempts: 0,
              Onaylı: false,
            },
          ];
        }
        if (item == "status") {
          this.new_customer_info[item] = "C";
        }
        if (item == "attempts") {
          this.new_customer_info[item] = 0;
        }
        if (item == "active") {
          this.new_customer_info[item] = true;
        }
        if (item == "create_date") {
          this.new_customer_info[item] = new Date().toISOString().split("T")[0];
        }
        if (item == "notes") {
          this.new_customer_info[item] = [];
        }
        if (item == "sms") {
          this.new_customer_info[item] = [];
        }
        if (item == "extra_data") {
          this.new_customer_info[item] = [];
        }
        if (item == "products") {
          this.new_customer_info[item] = [];
        }
      }
    },
    filterChanged() {
      this.get_customer_page = 1;
      this.getCustomers();
    },
    recordPerPage(item) {
      this.get_customer_record_per_page = item;
      this.get_customer_page = 1;
      this.getCustomers();
    },
    next(page) {
      this.get_costomer_page = page;
      this.getCustomers();
    },
    selectCustomer: async function (selected_customer) {
      // selected_customer = this.$refs["cgrid"].rawGrid.dataSource._filterData._filterdList[selected_customer.row - 1];
      console.log("selected_customer", selected_customer);
      this.is_progress = true;
      this.selected_customer = selected_customer;
      this.is_selected = true;
      console.log("this.is_selected", this.is_selected);
      console.log("selected_customer", this.selected_customer);

      await this.getCustomerDetails(selected_customer);
      this.getOldNotes(selected_customer);
    },
    getCustomerDetails: async function (selected_customer) {
      this.script = this.script_tmp;
      for (const key of Object.keys(selected_customer)) {
        this.script = this.script.replaceAll(
          "{{" + key + "}}",
          selected_customer[key]
        );
      }

      this.script = this.script.replace('{{vName}}', globalThis.user.name);
      this.script = this.script.replace('{{vSurname}}', globalThis.user.surname);
      this.script = this.script.replace('{{vDescription}}', globalThis.user.description);

      var response = (
        await this.$http_in.post(`crm/v1/CustomerDetails`, {
          data: JSON.stringify(selected_customer),
        })
      ).data;
      console.log("details", response);
      this.customer_extra_info = [];
      this.phone_list = [];
      this.product_list = [];
      this.note_list = [];
      this.sms_list = [];
      this.customer_extra_info = [...response.extra_data] ?? [];

      this.phone_list = [...response?.phones] ?? [];
      this.sms_list = [...response?.sms] ?? [];
      // this.phone_list = [...response?.phones.filter(e => e.status != 'P')] ?? [];
      this.product_list = [...response?.products] ?? [];

      for (const item of this.product_list) {
        item.is_selected = false;
      }
      this.note_list = [...response?.notes] ?? [];
      this.extra_data = {
        Araç: [...(response["Araç"] ?? [])],
        SSK: [...(response["SSK"] ?? [])],
        Tapu: [...(response["Tapu"] ?? [])],
        Mernis: [...(response["Mernis"] ?? [])],
        "Gsm Sorgu": [...(response["Gsm Sorgu"] ?? [])],
        Araç: [...(response["Araç"] ?? [])],
      };

      // this.phone_list.map((e) => {
      //   e.phone_log = response.phone_logs.filter(
      //     (f) => e["Telefon"].substr(-10) == f.RemoteNumber.substr(-10)
      //   );
      // });
      for (const row of response.phone_logs) {
        for (const key of Object.keys(row)) {
          console.log("key", key);
          if (!["Note", "Verdict", "RecordingFile"].includes(key)) {
            row[key] =
              typeof row[key] == "string"
                ? row[key].split("-").join(".").replace(/ /g, "\u00a0")
                : row[key];
          }
        }
      }

      for (const item of this.phone_list) {
        if (this.crm_permissions.includes("show_phone_details")) {
          item._showDetails = true;
        }
        item.phone_log = response.phone_logs.filter(
          (f) => item["Telefon"].substr(-10) == f.RemoteNumber.substr(-10)
        );
      }
      this.phone_list = this.phone_list.map((item) => ({ ...item }));

      this.product_list_header = [
        { key: "show_details", label: "" },
        { key: "select", label: "" },
      ];
      if (response.phones.length > 0) {
        for (const item of Object.keys(response.phones[0])) {
          console.log(item);
          if (
            ![
              "phone_log",
              "phone_logs",
              "cycles",
              "lastCallTime",
              "is_research",
              "_showDetails",
            ].includes(item)
          ) {
            this.phone_list_header.push({
              label:
                this.column_labels[item] != undefined
                  ? this.column_labels[item].replace(/ /g, "\u00a0")
                  : item.replace(/ /g, "\u00a0"),
              align: "left",
              sortable: true,
              key: item,
              class: "headerFont",
            });
          }
        }
      }

      if (response.products.length > 0) {
        for (const item of Object.keys(response.products[0])) {
          if (!["is_selected", "product_logs"].includes(item)) {
            this.product_list_header.push({
              label: item.replace(/ /g, "\u00a0"),
              align: "left",
              sortable: true,
              key: item,
              class: "headerFont",
            });
          }
        }
      }

      this.is_progress = false;

      // this.customer_list = response.customer_list;
    },

    getOldNotes: async function (selected_customer) {
      var response = (
        await this.$http_in.post(`crm/v1/OldNotes`, {
          data: JSON.stringify(selected_customer),
        })
      ).data;
      this.old_notes = [];
      for (let row of response) {
        for (let field of Object.keys(row)) {
          row[field] = row[field] ? row[field].replace(/ /g, "\u00a0").replaceAll('-', '/') : row[field];
        }
      }
      this.old_notes = [...response];
      // console.log('details', response);
    },
    add_note: async function () {
      this.note_list.push({
        insert_date: new Date().toLocaleString(),
        agent: globalThis.username,
        note: this.new_note,
      });
      this.modal_add_note = false;
      var response = (
        await this.$http_in.post(`crm/v1/CustomerNote`, {
          customer_info: JSON.stringify(this.selected_customer),
          data: JSON.stringify({
            insert_date: new Date().toLocaleString(),
            agent: globalThis.username,
            note: this.new_note,
          }),
        })
      ).data;
      this.new_note = "";
    },
    add_product_action: async function () {
      console.log("this.selected_item", this.selected_item);

      for (const item of this.selected_item) {
        var response = (
          await this.$http_in.post(`crm/v1/ProductActions`, {
            customer_info: JSON.stringify(this.selected_customer),
            data: JSON.stringify({
              "customer_id": this.selected_customer["Müşteri No"],
              "queue": this.selected_customer["Kuyruk"] ?? 'NA',
              "product_id": item["Ürün No"],
              "username": globalThis.user.username,
              ...this.p_action,
              ...item
            }),
          })
        ).data;
      }
      this.p_action.action_date = null;
      this.p_action.note = "";
      this.modal_product_action = false;
      this.selected_item = [];
      await this.getCustomerDetails(this.selected_customer);
    },
    open_delete_modal(item) {
      this.selected_phone = item;

      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_phone();
        }
      });
    },
    delete_phone: async function () {
      this.phone_list.splice(this.phone_list.indexOf(this.selected_phone), 1);
      var response = (
        await this.$http_in.delete(
          `crm/v1/CustomerPhone/${this.selected_customer._id}/${this.selected_phone.Telefon}`
        )
      ).data;
    },
    add_phone: async function () {
      try {
        var obj = Object.create(null);
        obj["Telefon"] = this.new_phone.split(" ").join("");
        obj["Sahibi"] = this.new_owner;
        obj["Onaylı"] = this.is_approved;
        obj["phone_log"] = [];
        obj["is_research"] = true;
        this.phone_list.push(obj);
        this.modal_add_phone = false;
      } catch (e) {
        console.log("error", e);
      }

      var response = (
        await this.$http_in.post(`crm/v1/CustomerPhone`, {
          customer_info: JSON.stringify(this.selected_customer),
          data: JSON.stringify(obj),
        })
      ).data;
    },
    add_extra_data: async function () {
      this.customer_extra_info.push(this.customer_extra_data);

      var response = (
        await this.$http_in.post(`crm/v1/CustomerExtraData`, {
          customer_info: this.selected_customer,
          data: this.customer_extra_info,
        })
      ).data;

      this.modal_extra_data = false;
      this.customer_extra_data = {
        column: "",
        value: "",
      };
      // Veri tabanına ve loglara kaydet.
    },
    delete_extra_data: async function (item) {
      let index = this.customer_extra_info.findIndex((x) => x === item);
      this.customer_extra_info.splice(index, 1);

      var response = (
        await this.$http_in.post(`crm/v1/CustomerExtraData`, {
          customer_info: this.selected_customer,
          data: this.customer_extra_info,
        })
      ).data;
    },
    set_approved: async function (item) {
      var response = (
        await this.$http_in.post(`crm/v1/SetPhoneApproved`, {
          customer_info: JSON.stringify(this.selected_customer),
          data: item,
        })
      ).data;

      if (response.ok == 1) {
      }
    },
    update_customer_info: async function () {
      try {
        this.is_progress = true;
        let _personal_info = { _id: this.selected_customer._id };
        for (const item of Object.keys(this.tmp_selected_customer)) {
          if (
            this.tmp_selected_customer[item] != this.selected_customer[item]
          ) {
            _personal_info[item] = this.tmp_selected_customer[item];
          }
        }

        let _phone_info = [];
        for (let index = 0; index < this.tmp_phone_list.length; index++) {
          if (
            this.phone_list[index]["Telefon"] !=
            this.tmp_phone_list[index]["Telefon"] ||
            this.phone_list[index]["Sahibi"] !=
            this.tmp_phone_list[index]["Sahibi"]
          ) {
            _phone_info.push({
              _phone: this.phone_list[index]["Telefon"],
              ...this.tmp_phone_list[index],
            });
          }
        }

        console.log("_personal_info", _personal_info);
        console.log("_phone_info", _phone_info);

        var response = (
          await this.$http_in.post(`crm/v1/SetCustomer`, {
            customer_info: _personal_info,
            phone_info: _phone_info,
          })
        ).data;

        if (response.acknowledged) {
          this.selected_customer = {
            ...this.selected_customer,
            ..._personal_info,
          };

          await this.getCustomerDetails(this.selected_customer);

          this.$swal({
            icon: "success",
            title: globalThis._lang("t_processSuccess"),
            confirmButtonText: this.lang("t_ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      } catch (error) {
      } finally {
        this.is_progress = false;
      }
    },
    // TO DO
    save_finish_code: async function () {
      if (!this.f_action.finish_code) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      if (this.f_action.finish_code.require_date && !this.f_action.action_date) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      var response = (
        await this.$http_in.post(`crm/v1/ManualCallAction`, {
          customer_info: this.selected_customer,
          data: this.f_action,
        })
      ).data;

      this.f_action = {};
      this.getCustomerDetails(this.selected_customer);
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`crm/v1/Users`)).data;

      this.agents = response;
    },
    getCustomerByPhone: async function (phone) {
      var response = (
        await this.$http_in.get(`/crm/v1/CustomersByPhone/${phone}`)
      ).data;
      if (response.result.length > 0) {
        this.is_progress = true;
        this.customer_list = [...response.result];
        this.headers = [];

        for (const item of this.columns) {
          if (
            [
              "_id",
              "campaign",
              "assign_agent",
              "phones",
              "products",
              "notes",
              "active",
              "cycles",
              "extra_data",
              "sms",
              "TCKN_masked",
            ].includes(item)
          ) {
            continue;
          }
          this.TableFilters[item] = [];
          this.EmptyTableFilters[item] = [];

          if (
            !Object.keys(this.filter_db).includes(
              item == 'TCKN' ? this.getTCKN() : item
            )
          ) {
            this.filter_db[item == 'TCKN' ? this.getTCKN() : item] = {
              data: [],
              loading: false,
              items: [],
            };
          }

          this.headers.push({
            label:
              this.column_labels[item] == undefined
                ? item
                : this.column_labels[item],
            align: "left",
            sortable: true,
            customFilterable: true,
            key: item == 'TCKN' ? this.getTCKN() : item,
            class: "headerFont",
            visible: (
              this.saved_headers.find((e) => e.key == item) || { visible: true }
            ).visible
              ? true
              : false,
          });

          this.headers = this.headers.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
        }
        if (globalThis.LayoutContentRendererDefault.current_call_info) {
          globalThis.LayoutContentRendererDefault.current_call_info.customer_no =
            this.customer_list[0]["Müşteri No"];
        }

        await this.selectCustomer(this.customer_list[0]);
      } else {
        if (this.crm_permissions.includes("add_customer")) {
          this.modal_add_customer = true;
          this.reset_customer_info();
          this.new_customer_info.phones[0].Telefon =
            globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.substr(
              -10
            ) || "";
        }
      }
    },
    getCustomerContactID: async function (_id) {
      var response = (
        await this.$http_in.get(`/crm/v1/CustomerByContactID/${_id}`)
      ).data;
      if (response.result.length > 0) {
        this.is_progress = true;
        this.customer_list = [...response.result];
        this.headers = [];

        for (const item of this.columns) {
          if (
            [
              "_id",
              "campaign",
              "assign_agent",
              "phones",
              "products",
              "notes",
              "active",
              "cycles",
              "extra_data",
              "sms",
              "TCKN_masked",
            ].includes(item)
          ) {
            continue;
          }
          this.TableFilters[item] = [];
          this.EmptyTableFilters[item] = [];

          if (
            !Object.keys(this.filter_db).includes(
              item == 'TCKN' ? this.getTCKN() : item
            )
          ) {
            this.filter_db[item == 'TCKN' ? this.getTCKN() : item] = {
              data: [],
              loading: false,
              items: [],
            };
          }

          this.headers.push({
            label:
              this.column_labels[item] == undefined
                ? item
                : this.column_labels[item],
            align: "left",
            sortable: true,
            customFilterable: true,
            key: item == 'TCKN' ? this.getTCKN() : item,
            class: "headerFont",
            visible: (
              this.saved_headers.find((e) => e.key == item) || { visible: true }
            ).visible
              ? true
              : false,
          });

          this.headers = this.headers.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
        }
        if (globalThis.LayoutContentRendererDefault.current_call_info) {
          globalThis.LayoutContentRendererDefault.current_call_info.customer_no =
            this.customer_list[0]["Müşteri No"];
        }

        await this.selectCustomer(this.customer_list[0]);
      } else {
        if (this.crm_permissions.includes("add_customer")) {
          this.modal_add_customer = true;
          this.reset_customer_info();
          this.new_customer_info.phones[0].Telefon =
            globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.substr(
              -10
            ) || "";
        }
      }
    },
    send_sms: async function () {
      if (!this.selected_sms_settings) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      if (!this.new_sms) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }


      this.new_sms = this.new_sms.replace('{{vName}}', globalThis.user.name);
      this.new_sms = this.new_sms.replace('{{vSurname}}', globalThis.user.surname);
      this.new_sms = this.new_sms.replace('{{vDescription}}', globalThis.user.description);
      var response;

      if (this.sms_type == 'sms') {
        response = (
          await this.$http_in.post(`spv/v1/SendSms`, {
            data: {
              phones: this.sms_phone,
              messages: this.new_sms,
            },
            sending_info: {
              selected_project: {
                internal_name: "Özel Gönderim",
              },
              selected_sms_template: {
                internal_name: "",
              },
              selected_filter: {
                internal_name: "",
              },
              sms_settings: this.selected_sms_settings,
              collection: globalThis.user.selected_project + "_customers",
            },
          })
        ).data;
      } else {
        response = (
          await this.$http_in.post(`text-channel-wp/v1/SendMessage`, {
            data: {
              phones: this.sms_phone,
              messages: this.new_sms,
            },
            sending_info: {
              selected_project: {
                internal_name: "Whatsapp Özel Gönderim",
              },
              selected_sms_template: {
                internal_name: "",
              },
              selected_filter: {
                internal_name: "",
              },
              sms_settings: this.selected_sms_settings,
              collection: globalThis.user.selected_project + "_customers",
            },
          })
        ).data;
      }


      if (response.status == "success") {
        this.sms_list.push({
          Tarih: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
          Gönderen: globalThis.username,
          Telefon: this.sms_phone,
          Mesaj: this.new_sms,
        });

        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        this.modal_send_sms = false;
        this.new_sms = "";
        this.sms_phone = "";
      }
    },
    GetSmsSettings: async function () {
      var response = (await this.$http_in.get(`crm/v1/SmsSettings`)).data;

      this.sms_settings = response;
    },
    GetProjects: async function () {
      var response = (
        await this.$http.get(`/Projects`, {
          headers: {
            authorization: globalThis.token,
          },
        })
      ).data;

      this.projects = response;
    },
    GetQueues: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Queue`, {
          headers: {
            authorization: globalThis.token,
          },
        })
      ).data;

      this.queues = response;
    },
    get_product_actions: async function () {
      var response = (
        await this.$http.get(`/ProductActions`, {
          headers: {
            authorization: globalThis.token,
            project: `wow_project_proje3`,
          },
        })
      ).data;
      //console.log('get_product_actions', response[0].WrapUpCodes);
      this.product_actions = response[0]?.WrapUpCodes
        ? response[0].WrapUpCodes
        : [];
    },
    filterSearch: async function (searchTerm, col) {
      clearTimeout(this.filterSearchInterval);
      //  await new Promise(resolve => setTimeout(resolve, 1000));

      this.filterSearchInterval = setTimeout(async () => {
        this.filter_db[col].loading = true;
        var response = (
          await this.$http_in.post(`crm/v1/FilterSearch`, {
            searchTerm,
            col,
          })
        ).data;

        let items = [];
        for (const item of response.result) {
          items.push(item._id);
        }
        this.filter_db[col].items = [...items];
        this.filter_db[col].loading = false;
        this.filter_db = { ...this.filter_db };
      }, 1000);
    },
    filterChanged() {
      this.get_customer_page = 1;
      this.getCustomers();
    },
    save_columns: async function () {
      var response = (
        await this.$http_in.post(`crm/v1/Settings`, {
          visible_headers: this.headers,
        })
      ).data;
      this.get_columns();
      // console.log(visible_headers.map(h => h.value));
    },
    async get_all_by_field(field) {
      var response = (
        await this.$http_in.post(`crm/v1/GetAllFilterByField`, { field })
      ).data;
      this.filter_db[field].items = [];
      console.log("get_all_by_field", response);
      let items = [];
      for (const item of this.sortByKey(response.result, "_id")) {
        items.push(item._id);
      }
      this.filter_db[field].items = items;
    },
    clearFilter() {
      for (const item of Object.keys(this.filter_db)) {
        this.filter_db[item].data = [];
      }
      this.filter_db = { ...this.filter_db };
      // var a = JSON.stringify(this.filter_db_empty);
      // this.filter_db = { ...JSON.parse(a) };

      this.TableFiltersNew = [{}];
      this.getCustomers();
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    get_columns: async function () {
      var response = (await this.$http_in.get(`crm/v1/Settings`)).data;
      console.log("column", response);
      this.saved_headers = [...(response.visible_headers || [])];
    },
    async assign_agent() {
      if (this.selected_assign_users.length == 0) {
        this.$swal({
          title: globalThis._lang('t_warning'),
          text: globalThis._lang('t_pleaseDoNotLeaveAnyBlankFields'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return;
      }

      this.get_customer_search_term = '';
      await this.getCustomers();

      if (this.selected_assign_type.internal_name == "pool") {
        this.$swal({
          title: globalThis._lang("t_areYouSure"),
          text: globalThis._lang(
            "t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure"
          ),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.lang("t_yes"),
          cancelButtonText: this.lang("t_cancel"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          console.log("res", result);
          if (result.value) {
            this.pool_assign();
          }
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_areYouSure"),
          text: `${this.get_customer_total_record} ${globalThis._lang(
            "t_amountOfCasesWillBeUpdatedAreYouSure"
          )}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.lang("t_yes"),
          cancelButtonText: this.lang("t_cancel"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          console.log("res", result);
          if (result.value) {
            this.shuffle();
          }
        });
      }
    },
    shuffle: async function () {
      console.log(this.TableFiltersNew);
      // var _filter = {};
      // for (const item of Object.keys(this.TableFiltersNew[0])) {
      //   console.log(item);
      //   if (this.TableFiltersNew[0][item] != undefined) {
      //     if (this.TableFiltersNew[0][item].includes(",")) {
      //       _filter[item] = { $in: this.TableFiltersNew[0][item].split(",") };
      //     } else {
      //       _filter[item] = this.TableFiltersNew[0][item];
      //     }
      //   }
      // }
      var _filter = {};
      if (this.TableFiltersNew.length > 0) {
        for (const item of Object.keys(this.TableFiltersNew[0])) {
          console.log(item);
          if (
            this.TableFiltersNew[0][item] &&
            Object.keys(this.TableFiltersNew[0][item]).length != 0 &&
            !["create_date", "action_date", "insert_date"].includes(item)
          ) {
            if (this.TableFiltersNew[0][item][item].includes(",")) {
              _filter[item] = {
                $in: this.TableFiltersNew[0][item][item].split(","),
              };
            } else {
              _filter[item] = this.TableFiltersNew[0][item][item];
            }
          } else {
            if (["create_date", "action_date", "insert_date"].includes(item)) {
              // console.log("item", item);
              console.log(
                "this.TableFiltersNew[0][item]",
                this.TableFiltersNew[0][item]
              );
              if (
                this.TableFiltersNew[0][item] &&
                this.TableFiltersNew[0][item].includes(",")
              ) {
                _filter[item] = {
                  $in: this.TableFiltersNew[0][item].split(","),
                };
              } else {
                _filter[item] = this.TableFiltersNew[0][item];
              }
            }
          }
        }
      }
      console.log("_filter", _filter);

      this.is_progress = true;
      await this.$http_in.post("crm/v1/ShuffleAssignedAgents", {
        filters: _filter,
        agents: this.selected_assign_users,
        assing_product: this.assign_for_product_agent,
        field: this.selected_assign_product_fields,
      });
      this.is_progress = false;
      this.getCustomers();
      this.selected_assign_users = [];
    },
    pool_assign: async function () {
      var response = (
        await this.$http_in.post(`crm/v1/SetAssignAgent`, {
          data: this.selected_assign_users,
          assing_product: this.assign_for_product_agent,
          field: this.selected_assign_product_fields,
        })

      ).data;

      var success_count = response.success_count;
      var failed_count = response.failed_count;
      if (failed_count > 0) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: `${failed_count} ${globalThis._lang(
            "t_casesCouldNotBeAssigned"
          )}`,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.getCustomers();
        this.$swal({
          icon: "success",
          title: globalThis._lang("t_processSuccess"),
          text: `${success_count} ${globalThis._lang(
            "t_customersHaveSuccessfullyBeenAssigned"
          )}`,
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
      this.selected_assign_users = [];
    },

    async CustomerListExport() {
      // var _filter = {};
      // if (this.TableFiltersNew.length > 0) {
      //   for (const item of Object.keys(this.TableFiltersNew[0])) {
      //     console.log(item);
      //     if (
      //       this.TableFiltersNew[0][item] &&
      //       Object.keys(this.TableFiltersNew[0][item]).length != 0
      //     ) {
      //       // Check if the property exists before calling 'includes'
      //       if (
      //         this.TableFiltersNew[0][item][item] &&
      //         this.TableFiltersNew[0][item][item].includes(",")
      //       ) {
      //         _filter[item] = {
      //           $in: this.TableFiltersNew[0][item][item].split(","),
      //         };
      //       } else {
      //         _filter[item] = this.TableFiltersNew[0][item][item];
      //       }
      //     }
      //   }
      // }
      var _filter = {};
      if (this.TableFiltersNew.length > 0) {
        for (const item of Object.keys(this.TableFiltersNew[0])) {
          console.log(item);
          if (
            this.TableFiltersNew[0][item] &&
            Object.keys(this.TableFiltersNew[0][item]).length != 0 &&
            !["create_date", "action_date", "insert_date"].includes(item)
          ) {
            if (this.TableFiltersNew[0][item][item].includes(",")) {
              _filter[item] = {
                $in: this.TableFiltersNew[0][item][item].split(","),
              };
            } else {
              _filter[item] = this.TableFiltersNew[0][item][item];
            }
          } else {
            if (["create_date", "action_date", "insert_date"].includes(item)) {
              // console.log("item", item);
              console.log(
                "this.TableFiltersNew[0][item]",
                this.TableFiltersNew[0][item]
              );
              if (
                this.TableFiltersNew[0][item] &&
                this.TableFiltersNew[0][item].includes(",")
              ) {
                _filter[item] = {
                  $in: this.TableFiltersNew[0][item].split(","),
                };
              } else {
                _filter[item] = this.TableFiltersNew[0][item];
              }
            }
          }
        }
      }

      this.is_progress = true;
      var rpr = (
        await this.$http_in.post("crm/v1/CustomerListExport", {
          filter: _filter,
        })
      ).data;
      console.log("rpr", rpr);

      try {
        while (true) {
          // let response = await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`);
          let response = await this.$http_ex.get(`${rpr}`);
          if (response.status == 200) {
            var anchor = document.createElement("a");
            anchor.href = `${rpr}`;
            anchor.download = `${rpr}`;
            // anchor.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            // anchor.download = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.target = "_blank";
            document.body.appendChild(anchor);
            anchor.click();
            this.is_progress = false;
            break;
          }
        }
      } catch (error) { }

      // this.is_progress = false;
      // if (![undefined, null, ""].includes(rpr)) {
      //   globalThis.window.location.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
      // }
    },
    async CustomerListWithPhoneDetailsExport() {
      var _filter = {};
      if (this.TableFiltersNew.length > 0) {
        for (const item of Object.keys(this.TableFiltersNew[0])) {
          console.log(item);
          if (
            this.TableFiltersNew[0][item] &&
            Object.keys(this.TableFiltersNew[0][item]).length != 0 &&
            !["create_date", "action_date", "insert_date"].includes(item)
          ) {
            if (this.TableFiltersNew[0][item][item].includes(",")) {
              _filter[item] = {
                $in: this.TableFiltersNew[0][item][item].split(","),
              };
            } else {
              _filter[item] = this.TableFiltersNew[0][item][item];
            }
          } else {
            if (["create_date", "action_date", "insert_date"].includes(item)) {
              // console.log("item", item);
              console.log(
                "this.TableFiltersNew[0][item]",
                this.TableFiltersNew[0][item]
              );
              if (
                this.TableFiltersNew[0][item] &&
                this.TableFiltersNew[0][item].includes(",")
              ) {
                _filter[item] = {
                  $in: this.TableFiltersNew[0][item].split(","),
                };
              } else {
                _filter[item] = this.TableFiltersNew[0][item];
              }
            }
          }
        }
      }
      this.is_progress = true;
      var rpr = (
        await this.$http_in.post("crm/v1/DumpCustomersTable", {
          filter: _filter,
        })
      ).data;
      console.log("rpr", rpr);
      try {
        while (true) {
          // let response = await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`);
          let response = await this.$http_ex.get(`${rpr}`);
          if (response.status == 200) {
            var anchor = document.createElement("a");
            // anchor.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.href = `${rpr}`;
            anchor.download = `${rpr}`;
            // anchor.download = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.target = "_blank";
            document.body.appendChild(anchor);
            anchor.click();
            this.is_progress = false;
            break;
          }
        }
      } catch (error) { }

      // this.is_progress = false;
      // if (![undefined, null, ""].includes(rpr)) {
      //   globalThis.window.location.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
      // }
    },
    async CustomerListWithProductDetailsExport() {
      var _filter = {};
      if (this.TableFiltersNew.length > 0) {
        for (const item of Object.keys(this.TableFiltersNew[0])) {
          console.log(item);
          if (
            this.TableFiltersNew[0][item] &&
            Object.keys(this.TableFiltersNew[0][item]).length != 0 &&
            !["create_date", "action_date", "insert_date"].includes(item)
          ) {
            if (this.TableFiltersNew[0][item][item].includes(",")) {
              _filter[item] = {
                $in: this.TableFiltersNew[0][item][item].split(","),
              };
            } else {
              _filter[item] = this.TableFiltersNew[0][item][item];
            }
          } else {
            if (["create_date", "action_date", "insert_date"].includes(item)) {
              // console.log("item", item);
              console.log(
                "this.TableFiltersNew[0][item]",
                this.TableFiltersNew[0][item]
              );
              if (
                this.TableFiltersNew[0][item] &&
                this.TableFiltersNew[0][item].includes(",")
              ) {
                _filter[item] = {
                  $in: this.TableFiltersNew[0][item].split(","),
                };
              } else {
                _filter[item] = this.TableFiltersNew[0][item];
              }
            }
          }
        }
      }
      this.is_progress = true;
      var rpr = (
        await this.$http_in.post("crm/v1/ProductDetails", {
          filter: _filter,
        })
      ).data;
      console.log("rpr", rpr);
      try {
        while (true) {
          // let response = await this.$http_ex.get(`${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`);
          let response = await this.$http_ex.get(`${rpr}`);
          if (response.status == 200) {
            var anchor = document.createElement("a");
            // anchor.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.href = `${rpr}`;
            anchor.download = `${rpr}`;
            // anchor.download = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
            anchor.target = "_blank";
            document.body.appendChild(anchor);
            anchor.click();
            this.is_progress = false;
            break;
          }
        }
      } catch (error) { }

      // this.is_progress = false;
      // if (![undefined, null, ""].includes(rpr)) {
      //   globalThis.window.location.href = `${globalThis.env.PROTOCOL}://${globalThis.env.CRM_URL}/${rpr}.xlsx`;
      // }
    },
    async export_csv() {
      var _filter = {};
      if (this.TableFiltersNew.length > 0) {
        for (const item of Object.keys(this.TableFiltersNew[0])) {
          console.log(item);
          if (this.TableFiltersNew[0][item].includes(",")) {
            _filter[item] = { $in: this.TableFiltersNew[0][item].split(",") };
          } else {
            _filter[item] = this.TableFiltersNew[0][item];
          }
        }
      }

      this.is_progress = true;
      var rpr = (
        await this.$http.post(
          "/ExportTtsCsvByProduct",
          {
            collection: `wow_project_proje3_customers`,
            filter: _filter,
          },
          {
            headers: { authorization: globalThis.token },
          }
        )
      ).data;
      console.log("rpr", rpr);
      this.is_progress = false;
      if (![undefined, null, ""].includes(rpr)) {
        globalThis.window.location.href = `http://${globalThis.AppConfig.RESTAPI}/outputs/${rpr}`;
      }
    },
    get_sms_template: async function () {
      var response = (await this.$http_in.get(`/spv/v1/SmsTemplate`)).data;
      console.log("SmsTemplates", response);
      this.sms_templates = response;
    },
    set_sms_template(item) {
      let msg = item.message;

      for (const key of Object.keys(this.selected_customer)) {
        msg = msg.replace("{{" + key + "}}", this.selected_customer[key]);
      }
      this.new_sms = msg;
    },

    get_script: async function () {
      try {
        var response = (await this.$http_in.get(`/crm/v1/Script`)).data;

        this.script_tmp = response[0].content || "";
        this.script = response[0].content || "";
      } catch (error) {
        this.script_tmp = "";
        this.script = "";
      }
    },
    async add_new_customer() {
      this.new_customer_info["Kuyruk"] = this.new_customer_info.queue;
      if (
        [null, undefined, ""].includes(this.new_customer_info["Müşteri No"])
      ) {
        this.new_customer_info["Müşteri No"] = new Date().getTime().toString();
      }

      if (
        this.new_customer_info.queue == "" ||
        ["anonymous", "Unknown", ""].includes(
          this.new_customer_info.phones[0].Telefon
        )
      ) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      delete this.new_customer_info.queue;
      var response = (
        await this.$http_in.post(`crm/v1/NewCustomer`, {
          new_customer_info: this.new_customer_info,
        })
      ).data;

      this.getCustomerByPhone(
        this.new_customer_info.phones[0].Telefon
      );
    },
  },
  mounted: async function () {
    if (!localStorage.hasOwnProperty("crmData")) {
      this.crm_permissions = globalThis.permissions["crm"] ?? [];
      this.other_permissions = globalThis.permissions["other"] ?? [];
      this.is_progress = true;

      // Promise.all(
      await this.get_columns();
      await this.getCustomerColumns();
      this.get_users();
      this.get_sms_template();
      this.GetFinishCodes();
      this.GetSmsSettings();
      this.get_wp_providers();
      // this.get_product_actions();
      this.get_all_by_field("finish_code");
      this.get_all_by_field("Kuyruk");
      this.get_all_by_field("status");
      this.get_all_by_field("attempts");
      this.get_all_by_field("total_attempts");
      this.get_script();

      // this.GetQueues();
      // );
    }

    const this_data = JSON.parse(localStorage.getItem("crmData"));
    console.log("this_data", this_data);
    if (![undefined, null].includes(this_data)) {
      for (let [key, val] of Object.entries(this_data)) {
        this[key] = val;
      }
    }
    await this.get_columns();
    await this.getCustomerColumns();

    if (
      this.crm_permissions.includes("display_all_records") &&
      !this.call_list_items.find(e => e.internal_name == 'all')
    ) {
      this.call_list_items.push({ internal_name: 'all', display_name: globalThis._lang("t_all") });
    }

    // if (!this.crm_permissions.includes("crm_display_sound")) {
    //   this.phone_log_header = this.phone_log_header.filter(
    //     (e) => e.key != "RecordingFile"
    //   );
    // }

    this.is_progress = false;

    globalThis.crm = this;
    this.queues = globalThis.queues;
    console.log(
      "globalThis.LayoutContentRendererDefault.current_call",
      globalThis.LayoutContentRendererDefault.current_call
    );
    // globalThis.LayoutContentRendererDefault.current_call_info.Extra.Direction != 'outbound'
    if (
      globalThis.LayoutContentRendererDefault.current_call_info != null &&
      globalThis.v_navbar.other_permission.includes("crm_popup")
    ) {
      this.getCustomerByPhone(
        globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.substr(
          -10
        )
      );
    }
  },
  beforeDestroy() {
    localStorage.setItem("crmData", JSON.stringify(this.$data));
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.table-style {
  color: black !important;
}
// .table-style :hover {
//   color: white !important;
// }

.cell-style {
  color: black;
}

.override-overflow {
  overflow: hidden !important;
}
</style>
